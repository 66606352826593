import { Typography } from "@mui/material";
import { isFuture, isPast } from "date-fns";
import { EventWithUserAttendance, PreviousEvent } from "../../../api";
import { getDayDiff, getFormattedWhen } from "../../../shared-functions";
import { AttendanceModalEventInfo } from "../../AttendanceModal/types";
import { LoggedInUserAttendanceModal } from "../../LoggedInUser/LoggedInUserAttendanceModal";
import { LinkRowData } from "../../ui/TableWithSearch";


const getLoggedInUserAttendanceModal = (event: EventWithUserAttendance): JSX.Element => {
    const disable =
        (event.registrationDeadline ?
            isPast(event.registrationDeadline) &&
            event.attendance?.attendanceStatus !== "Attending" &&
            event.attendance?.attendanceStatus !== "Waiting"
        :   true) ||
        (event.openingTimeForRegistration ? isFuture(event.openingTimeForRegistration) : true);

    return (
        <LoggedInUserAttendanceModal
            disableAttendanceRegistration={disable}
            event={event as AttendanceModalEventInfo}
            attendance={event.attendance}
            eventDto={event}
        />
    );
};

export const generateFutureEventsTableData = (
    events: EventWithUserAttendance[],
    isDesktop = true
): LinkRowData[] => {
    return isDesktop ?
            generateDesktopFutureEventsTableData(events)
        :   generateMobileFutureEventsTableData(events);
};

export const generatePreviousEventsTableData = (
    events: PreviousEvent[],
    isDesktop = true
): LinkRowData[] => {
    return isDesktop ?
            generateDesktopPreviousEventsTableData(events)
        :   generateMobilePreviousEventsTableData(events);
};

const generateDesktopFutureEventsTableData = (events: EventWithUserAttendance[]): LinkRowData[] => {
    return events.flatMap((event) => {
        if (!event.eventId) {
            return [];
        }

        const id = event.eventId!;
        const rowHref = `/event/${event.eventId}`;
        const rowEventType = event.eventType;

        return {
            key: id,
            href: rowHref,
            eventType: rowEventType,
            columns: [
                {
                    key: "name",
                    content: event.name ?? "",
                    sortableByColumn: false,
                },
                {
                    key: "location",
                    content: event.location ?? "",
                    sortableByColumn: false,
                },
                {
                    key: "startDateTime",
                    content: getFormattedWhen(event.startDateTime!, "nb"),
                    sortableByColumn: false,
                },
                {
                    key: "registrationDeadline",
                    content: getDayDiff(event.registrationDeadline ?? event.startDateTime!),
                    sortableByColumn: false,
                },
                {
                    key: "attendance",
                    content: getLoggedInUserAttendanceModal(event),
                    shouldNotLink: true,
                    sortableByColumn: false,
                },
            ],
        };
    });
};

const generateMobileFutureEventsTableData = (events: EventWithUserAttendance[]): LinkRowData[] => {
    return events.flatMap((event) => {
        if (!event.eventId) {
            return [];
        }

        const id = event.eventId!;
        const rowHref = `/event/${event.eventId}`;
        const rowEventType = event.eventType;
        const formattedDate = getFormattedWhen(event.startDateTime!, "nb", undefined, true);

        return {
            key: id,
            href: rowHref,
            eventType: rowEventType,
            columns: [
                {
                    key: "name",

                    content: (
                        <div>
                            <Typography
                                component="p"
                                variant="body1"
                                sx={{ wordBreak: "break-word" }}
                            >
                                {event.name ?? ""}
                            </Typography>
                            <Typography component="p" variant="body2">
                                {formattedDate ?? ""}
                            </Typography>
                        </div>
                    ),
                    stringContent: `${event.name} ${formattedDate}`,
                    sortableByColumn: false,
                },
                {
                    key: "attendance",
                    content: getLoggedInUserAttendanceModal(event),
                    shouldNotLink: true,
                    sortableByColumn: false,
                },
            ],
        };
    });
};

export const generateDesktopPreviousEventsTableData = (events: PreviousEvent[]): LinkRowData[] => {
    return events.flatMap((event) => {
        if (!event.eventId) {
            return [];
        }

        const id = event.eventId!;
        const rowHref = `/event/${event.eventId}`;
        const rowEventType = event.eventType;

        return {
            key: id,
            href: rowHref,
            eventType: rowEventType,
            columns: [
                {
                    key: "name",
                    content: event.eventName ?? "",
                    sortableByColumn: false,
                },
                {
                    key: "location",
                    content: event.location ?? "",
                    sortableByColumn: false,
                },
                {
                    key: "startDateTime",
                    content: getFormattedWhen(event.startDateTime!, "nb", event.endDateTime),
                    sortableByColumn: false,
                },
            ],
        };
    });
};

export const generateMobilePreviousEventsTableData = (events: PreviousEvent[]): LinkRowData[] => {
    return events.flatMap((event) => {
        if (!event.eventId) {
            return [];
        }

        const id = event.eventId!;
        const rowHref = `/event/${event.eventId}`;
        const rowEventType = event.eventType;
        const formattedDate = getFormattedWhen(event.startDateTime!, "nb", event.endDateTime, true);

        return {
            key: id,
            href: rowHref,
            eventType: rowEventType,
            columns: [
                {
                    key: "name",
                    content: (
                        <div>
                            <Typography
                                component="p"
                                variant="body1"
                                sx={{ wordBreak: "break-word" }}
                            >
                                {event.eventName ?? ""}
                            </Typography>
                            <Typography component="p" variant="body2">
                                {formattedDate}
                            </Typography>
                        </div>
                    ),
                    stringContent: `${event.eventName} ${formattedDate}`,
                    sortableByColumn: false,
                },
            ],
        };
    });
};
