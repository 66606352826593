import { Box, Container, Link, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
    heightInPx: number;
}

export const Footer = ({ heightInPx }: Props) => {
    const theme = useTheme();
    const translations = useTranslation("translation", {
        keyPrefix: "footer",
    }).t;

    return (
        <Box minHeight={heightInPx + "px"} display={"flex"} sx={{ boxShadow: 5 }}>
            <Container
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: "0.7rem",
                    paddingBottom: "0.7rem",
                }}
            >
                <Typography textAlign={"center"}>
                    {translations("info")}
                    &nbsp;
                    <Link
                        color={theme.palette.secondary.main}
                        href="https://bouvetasa.sharepoint.com/sites/BouvetEvent-Infoogtilbakemeldinger"
                        target="_blank"
                    >
                        {translations("readMore")}
                    </Link>
                </Typography>
            </Container>
        </Box>
    );
};
