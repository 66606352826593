type Environment = "dev" | "test" | "production";

interface AppConfig {
    environment: Environment;
    buildId?: string;

    apiBaseURL?: string;

    defaultLanguage: string;
    fallbackLanguage: string;

    appInsightsConnectionString?: string;
}

export const appConfig: AppConfig = {
    environment: import.meta.env.VITE_ENVIRONMENT as Environment,
    buildId: import.meta.env.VITE_BUILDID ?? "dev",
    apiBaseURL:
        import.meta.env.VITE_APIBASEURL ?? `${window.location.protocol}//${window.location.host}`,
    appInsightsConnectionString: import.meta.env.VITE_APPINSIGHTSCONNECTIONSTRING,
    defaultLanguage: "nb",
    fallbackLanguage: "nb",
};
