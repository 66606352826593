import { useContext } from "react";
import { SpinnerLargeCenter } from "../../ui/Spinner";
import { TableWithSearch } from "../../ui/TableWithSearch";
import { EventOverviewContext } from "../context";
import { usePreviousEventsQuery } from "../queries";
import { useEventTypeFilter } from "./filteringCategories";
import { generatePreviousEventsTableData } from "./tableDataGenerator";

interface Props {
    owned: boolean;
}

export const PreviousEvents = ({ owned }: Props) => {
    const { isDesktop, translations } = useContext(EventOverviewContext);
    const { data: events, isLoading } = usePreviousEventsQuery(owned);

    const tableHeads =
        isDesktop ? [translations("name"), translations("location"), translations("when")] : [];

    const rows = generatePreviousEventsTableData(events ?? [], isDesktop);

    const { filterComponents, filterCallback } = useEventTypeFilter();

    if (isLoading) {
        return <SpinnerLargeCenter />;
    }

    return (
        <TableWithSearch
            tableHeads={tableHeads}
            rows={rows}
            searchKeys={["name", "location", "startDateTime"]}
            extraFilterComponents={filterComponents}
            extraFilterCallback={filterCallback}
        />
    );
};
