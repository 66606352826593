import { formatDistanceToNow, isFuture } from "date-fns";
import { nb } from "date-fns/locale";
import { capitalizeFirstLetter } from "./capitalizeFirstLetter";
// TODO, make it care about locale

export const getDayDiff = (endDate: Date): string => {
    if (!isFuture(endDate)) return "Utgått";

    return formatDistanceToNow(endDate, { locale: nb }) + " igjen";
};

const desktopDateFormat = (hour12: boolean): Intl.DateTimeFormatOptions => ({
    weekday: "short",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12,
});

const mobileDateFormat = (hour12: boolean): Intl.DateTimeFormatOptions => ({
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12,
});

const timeFormat = (hour12: boolean): Intl.DateTimeFormatOptions => ({
    hour: "2-digit",
    minute: "2-digit",
    hour12,
});

export const getFormattedWhen = (
    startDate: Date,
    locale: string,
    endDate?: Date,
    mobileView?: boolean
): string => {
    const asDateAndTime: Intl.DateTimeFormatOptions =
        mobileView ? mobileDateFormat(false) : desktopDateFormat(false);
    const asTime = timeFormat(false);

    const date =
        endDate ?
            `${startDate.toLocaleDateString(locale, asDateAndTime)} - 
            ${endDate.toLocaleTimeString(locale, startDate.toDateString() === endDate.toDateString() ? asTime : asDateAndTime)}`
        :   startDate.toLocaleDateString(locale, asDateAndTime);

    return capitalizeFirstLetter(date);
};
