import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "../../api";

export const useFutureEventsQuery = (owned = false) => {
    const { eventOverviewClient } = useApiClient();

    return useQuery(["futureEvents", owned], async () => {
        return await eventOverviewClient.eventOverview(owned);
    });
};

export const usePreviousEventsQuery = (owned = false) => {
    const { eventOverviewClient } = useApiClient();

    return useQuery(["previousEvents", owned], async () => {
        return await eventOverviewClient.previous(owned);
    });
};
