import { AppBar, CssBaseline, Paper, useTheme } from "@mui/material";
import { Container } from "@mui/system";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as DarkHeaderLogo } from "../../assets/pictures/darkHeaderLogo.svg";
import { ReactComponent as LightHeaderLogo } from "../../assets/pictures/lightHeaderLogo.svg";

interface Props {
    children: ReactNode;
}

export const PageWrapper = ({ children }: Props): JSX.Element => {
    return (
        <>
            <CssBaseline />
            <SimpleHeader />
            <Container style={{ padding: "8px" }} maxWidth="md">
                <Paper>{children}</Paper>
            </Container>
        </>
    );
};

const SimpleHeader = (): JSX.Element => {
    const theme = useTheme();

    return (
        <div style={{ flexGrow: 1 }}>
            <AppBar position="static" color="transparent" enableColorOnDark>
                <Container
                    sx={{
                        height: "72px",
                        display: "flex",
                        alignItems: "center",
                        padding: "12px",
                        flexGrow: 1,
                        lineHeight: 0,
                        color: "transparent",
                    }}
                >
                    <Link to="/" style={{ flexGrow: 1, lineHeight: 0 }}>
                        {theme.palette.mode === "light" ?
                            <LightHeaderLogo style={{ width: "120px" }} />
                        :   <DarkHeaderLogo style={{ width: "120px" }} />}
                    </Link>
                </Container>
            </AppBar>
        </div>
    );
};
