import { AttendanceDto } from "../../api";
import { EmailModal } from "../EmailModal";
import { EmailModalEventInfo } from "../EmailModal/types";
import { useGetLoggedInUserEventAttendanceQuery } from "./queries";

interface Props {
    event: EmailModalEventInfo;
    attendance?: AttendanceDto;
}

export const LoggedInUserEmailModal = ({ event, attendance }: Props): JSX.Element => {
    const enableQuery = !attendance;

    const { data } = useGetLoggedInUserEventAttendanceQuery(event?.eventId ?? "", enableQuery);
    attendance = !attendance ? data : attendance;

    return (
        <>
            <EmailModal event={event} />
        </>
    );
};
