import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import { ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { EventsTableRender } from "./EventsTableRender";
import { ColumnData, LinkRowData } from "./types";

interface Props {
    tableHeads?: string[];
    rows: LinkRowData[];
    searchKeys: string[];
    extraFilterComponents?: ReactNode;
    extraFilterCallback?: (row: LinkRowData) => boolean;
}

export const TableWithSearch = ({
    tableHeads,
    rows,
    searchKeys,
    extraFilterComponents,
    extraFilterCallback,
}: Props) => {
    const translations = useTranslation("translation", {
        keyPrefix: "table",
    }).t;

    const [searchTerm, setSearchTerm] = useState("");
    const filteredRows = useMemo(
        () =>
            rows.filter((row) => {
                const matchingColumns: ColumnData[] = row.columns.filter((column) => {
                    if (searchKeys.indexOf(column.key) < 0) {
                        return false;
                    }

                    const textToSearch =
                        typeof column.content === "string" ? column.content : column.stringContent;

                    return textToSearch ?
                            textToSearch.toString().toLowerCase().includes(searchTerm)
                        :   false;
                });
                const textFilter = matchingColumns.length > 0;

                return (
                    textFilter && (extraFilterCallback === undefined || extraFilterCallback(row))
                );
            }),
        [rows, extraFilterCallback, searchKeys, searchTerm]
    );

    return (
        <>
            <TextField
                id="table-search"
                label={translations("search")}
                sx={{ width: "100%", mb: 2 }}
                variant="standard"
                color="secondary"
                onChange={(value) => setSearchTerm(value.target.value?.toLowerCase())}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" style={{ padding: 12 }}>
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            {extraFilterComponents}
            <EventsTableRender tableHeads={tableHeads} rows={filteredRows} />
        </>
    );
};
