import { Box, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GenericError } from ".";
import { PageWrapper } from "./PageWrapper";

export const NoLoggedInUserPage = (): JSX.Element => {
    const translations = useTranslation("translation", {
        keyPrefix: "error",
    }).t;

    return (
        <PageWrapper>
            <GenericError
                message={translations("attemptingLogin")}
                title={translations("loginInProgress")}
            />
            <Box sx={{ textAlign: "center" }}>
                <CircularProgress color="secondary" size={50} sx={{ mb: 2 }} />
            </Box>
        </PageWrapper>
    );
};
