import {
    AccessTime,
    EventAvailable,
    Group,
    GroupOutlined,
    HourglassTop,
    LockOpen,
    PeopleAlt,
    Person,
    PersonAdd,
    Place,
} from "@mui/icons-material";
import { Typography } from "@mui/material";
import { CalendarIcon } from "@mui/x-date-pickers";
import { isFuture } from "date-fns";
import { useTranslation } from "react-i18next";
import { stripHtml } from "string-strip-html";
import { EventDto } from "../../../api";
import { getFormattedWhen } from "../../../shared-functions";
import { InfoLine } from "./InfoLine";

interface Props {
    event: EventDto;
}

export const EventInfo = ({ event }: Props) => {
    const translations = useTranslation("translation", {
        keyPrefix: "event",
    }).t;

    return (
        <>
            <Typography variant="h4" sx={{ mb: 2, wordWrap: "break-word" }}>
                {event.name}
            </Typography>
            {event.description && (
                <Typography
                    variant="body1"
                    sx={{ pb: 3 }}
                    dangerouslySetInnerHTML={{
                        __html: stripHtml(event?.description ?? "", {
                            skipHtmlDecoding: true,
                            ignoreTags: [
                                "br",
                                "p",
                                "span",
                                "li",
                                "ol",
                                "s",
                                "i",
                                "a",
                                "u",
                                "h1",
                                "h2",
                                "h3",
                                "h4",
                                "h5",
                                "strong",
                            ],
                        }).result,
                    }}
                />
            )}
            {event.location && (
                <InfoLine
                    icon={<Place />}
                    title={translations("form.location")}
                    info={event.location}
                />
            )}
            {event.startDateTime && (
                <InfoLine
                    icon={<CalendarIcon />}
                    title={translations("form.timeOf")}
                    info={`${getFormattedWhen(event.startDateTime, "nb", event.endDateTime, false)} `}
                />
            )}
            {event.openingTimeForRegistration && isFuture(event.openingTimeForRegistration) ? (
                <InfoLine
                    icon={<LockOpen />}
                    title={translations("form.openingTimeForRegistration")}
                    info={`${getFormattedWhen(event.openingTimeForRegistration ?? new Date(), "nb", undefined, false)} `}
                />
            ) : null}
            {event.registrationDeadline && (
                <InfoLine
                    icon={<AccessTime />}
                    title={translations("form.registrationDeadline")}
                    info={`${getFormattedWhen(event.registrationDeadline, "nb", undefined, false)} `}
                />
            )}
            {event.eventType && (
                <InfoLine
                    icon={<Group />}
                    title={translations("form.eventCategory")}
                    info={`${translations("form.eventTypes." + event.eventType.toLowerCase())}`}
                />
            )}
            {event.owner?.name && (
                <InfoLine
                    icon={<Person />}
                    title={translations("form.organizer")}
                    info={event.owner.name}
                />
            )}
            {event.collaborators && event.collaborators.length > 0 && (
                <InfoLine
                    icon={<PeopleAlt />}
                    title={translations("form.collaborators")}
                    info={event.collaborators.map((user) => user.name).join(", ")}
                />
            )}
            {event.allowGuests && (
                <InfoLine
                    icon={<PersonAdd />}
                    title={translations("form.maxGuestsPerAttendee")}
                    info={`${event.maxGuestsPerAttendee} ${translations("form.pers")}`}
                />
            )}
            {event.maxAttendees! > 0 && (
                <InfoLine
                    icon={<Group />}
                    title={translations("form.maxAttendees")}
                    info={`${event.maxAttendees} ${translations("form.pers")}`}
                />
            )}
            {event.minAttendees! > 0 && (
                <InfoLine
                    icon={<GroupOutlined />}
                    title={translations("form.minAttendees")}
                    info={`${event.minAttendees} ${translations("form.pers")}`}
                />
            )}

            <InfoLine
                icon={<EventAvailable />}
                title={translations("form.signups")}
                info={`${event.numberOfParticipants ?? 0} ${translations("form.persons")}`}
            />

            {event.isFull && (
                <InfoLine
                    icon={<HourglassTop />}
                    title={translations("form.participantCount")}
                    info={`${event.numberOfWaitingParticipants} ${translations("form.persons")}`}
                />
            )}
        </>
    );
};
