import { isFuture } from "date-fns";
import { t } from "i18next";
import { AttendanceDto, EventDto } from "../../api";
import { AttendanceModal } from "../AttendanceModal";
import { AttendanceModalEventInfo } from "../AttendanceModal/types";
import { ColumnData, LinkRowData } from "../ui/TableWithSearch";
import { UserAvatar } from "../ui/UserAvatar";

const getAdminUserAttendanceModal = (
    attendance: AttendanceDto,
    event: EventDto,
    userIsOwnerOrAdmin = false
): JSX.Element => {
    if (!userIsOwnerOrAdmin) return <></>;

    const disable = event.startDateTime ? !isFuture(event.startDateTime) : true;

    return (
        <AttendanceModal
            userName={attendance.name}
            userImage={attendance.image}
            event={
                {
                    ...event,
                    eventId: event.id,
                    registrationDeadline: event.registrationDeadline,
                } as AttendanceModalEventInfo
            }
            isEventOpen={
                event.openingTimeForRegistration ?
                    !isFuture(event.openingTimeForRegistration)
                :   false
            }
            attendance={attendance}
            disableModal={disable}
            isAdminOrOwnerEditing
        />
    );
};

const generateDesktopTableData = (
    attendance: AttendanceDto,
    event: EventDto,
    isWaitingList?: boolean
): ColumnData[] => {
    const columns: ColumnData[] = [
        {
            key: "image",
            content: <UserAvatar userName={attendance.name} image={attendance.image} />,
            sortableByColumn: false,
        },
        {
            key: "name",
            content: attendance.name ?? "",
            sortableByColumn: true,
        },
        {
            key: "department",
            content: attendance.departmentName ?? "",
            sortableByColumn: true,
        },
        {
            key: "comment",
            content: attendance.comment ?? "",
            sortableByColumn: true,
        },
        {
            key: "status",
            content: t(`attendance.${attendance.attendanceStatus?.toLowerCase() ?? "invited"}`),
            sortableByColumn: true,
        },
    ];

    if (isWaitingList) {
        columns.splice(4, 0, {
            key: "waitListSpot",
            content: attendance.waitListSpot?.toString() ?? "",
            sortableByColumn: true,
        });
    }
    if (event?.allowGuests) {
        columns.splice(4, 0, {
            key: "numberOfGuests",
            content: attendance.guestCount?.toString() ?? "",
            sortableByColumn: true,
        });
    }

    return columns;
};

const generateMobileTableData = (attendance: AttendanceDto): ColumnData[] => {
    const columns: ColumnData[] = [
        {
            key: "name",
            content: attendance.name ?? "",
            sortableByColumn: true,
        },
        {
            key: "status",
            content: t(`attendance.${attendance.attendanceStatus?.toLowerCase() ?? "invited"}`),
            sortableByColumn: true,
        },
    ];

    return columns;
};

export const generateTableData = (
    attendances: AttendanceDto[],
    event: EventDto,
    userIsOwnerOrAdmin: boolean,
    isWaitingList?: boolean,
    isDesktop = true
): LinkRowData[] => {
    return attendances.flatMap((attendance) => {
        if (!attendance.userId) {
            return [];
        }
        const columns: ColumnData[] =
            isDesktop ?
                generateDesktopTableData(attendance, event, isWaitingList)
            :   generateMobileTableData(attendance);

        if (userIsOwnerOrAdmin) {
            columns.splice(columns.length, 0, {
                key: "attendance",
                content: getAdminUserAttendanceModal(attendance, event, userIsOwnerOrAdmin),
                shouldNotLink: true,
                sortableByColumn: false,
            });
        }

        return {
            key: attendance.userId,
            columns: columns,
        };
    });
};
