import { t } from "i18next";
import { FormFieldValue, ValidationResult, ValidationRules, ValidationType } from "./types";

export const validateAndGetErrorMessage = (
    fieldId: string,
    value: FormFieldValue,
    formData: object,
    validationRules: ValidationRules
): string | null => {
    const hasValidationRule = !!validationRules[fieldId]?.length;

    if (!hasValidationRule) {
        return null;
    }

    const fieldDisplayText = t(`event.form.${fieldId}`);

    const errorMsg = validationRules[fieldId].reduce((totalMsg: string, rule: ValidationType) => {
        if (!rule.isValid(value, formData)) {
            const errorMsg =
                rule.errorMsgKeys?.length === 1 ?
                    t(rule.errorMsgKeys[0])
                :   rule.errorMsgKeys.reduce((p, c) => t(p) + t(c));
            totalMsg = totalMsg ? `${totalMsg} og ${errorMsg.toLowerCase()}` : `${errorMsg}`;
        }

        return totalMsg;
    }, "");

    return errorMsg ? `${fieldDisplayText}: ${errorMsg}` : null;
};

export const isValidForm = (validationResult: ValidationResult): boolean =>
    !Object.values(validationResult).some((message) => !!message);
