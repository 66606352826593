import { Alert, Autocomplete, Box, Grid, Paper, TextField, Typography } from "@mui/material";
import { SyntheticEvent, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EditEventDto, EventDto, IEntraIdGroup, IEventDto } from "../../../api";
import { LoggedInUserContext } from "../../LoggedInUser/provider";
import { MutationSnackbar } from "../../ui/MutationSnackbar";
import { UserSelect } from "../../ui/UserSelect";
import { EventContext } from "../context";
import { useCreateEventQuery, useUpdateEventQuery } from "../queries";
import { FormFields } from "./FormFields";
import { OrganizationalStructureSelect } from "./OrganizationalStructure";
import { isValidForm } from "./validation/formValidation";

import { EventFormButtons } from "./EventFormButtons";
import { ValidationResult } from "./validation/types";

interface Props {
    template: EditEventDto;
    isCreating: boolean;
}

export const EventForm = ({ template, isCreating }: Props): JSX.Element => {
    const { translations } = useContext(EventContext);
    const loggedInUser = useContext(LoggedInUserContext);

    const navigate = useNavigate();

    const handleEventEdited = (id: string) => navigate(`/event/${id}`);

    const [updatedEvent, setUpdatedEvent] = useState<IEventDto>(template.eventDto!);
    const [errors, setErrors] = useState<ValidationResult>({});

    const createEventQuery = useCreateEventQuery(handleEventEdited);
    const updateEventQuery = useUpdateEventQuery(template.eventDto?.id ?? "", handleEventEdited);

    const { mutate, isLoading, error, isIdle } = isCreating ? createEventQuery : updateEventQuery;

    const firstRender = useRef(true);
    const setOwnerOnNewEvent = useCallback(() => {
        if (isCreating && firstRender.current) {
            firstRender.current = false;
            setUpdatedEvent({ ...template.eventDto!, owner: loggedInUser });
        }
    }, [isCreating, template, loggedInUser]);

    useEffect(() => setOwnerOnNewEvent());

    const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!isValidForm(errors)) {
            return;
        }

        mutate(updatedEvent as EventDto);
    };

    return (
        <Paper sx={{ mt: 2 }} component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2} sx={{ p: 3 }}>
                <Grid item xs={12} md={7}>
                    <FormFields
                        updatedEvent={updatedEvent}
                        eventType={template.eventDto?.eventType}
                        setUpdatedEvent={setUpdatedEvent}
                        users={template.allUsers}
                        errors={errors}
                        setErrors={setErrors}
                        isCreating={isCreating}
                    />
                    <Box mt={2}>
                        <EventFormButtons
                            isLoading={isLoading}
                            hasError={!isValidForm(errors)}
                            isCreating={isCreating}
                            eventId={template.eventDto?.id ?? ""}
                        />
                    </Box>
                    {!isValidForm(errors) && (
                        <Box mt={2}>
                            <FormErrorsAlert errors={errors} />
                        </Box>
                    )}
                </Grid>
                <Grid item xs={12} md={5}>
                    <Paper>
                        <Typography variant="body2" sx={{ pl: 4, pt: 2, pb: 2 }}>
                            {translations("organizationalStructureSelect.chooseDepartments")}
                        </Typography>
                        <OrganizationalStructureSelect
                            rootDepartment={template.departmentStructure!}
                            selectedDeptartmentIds={updatedEvent.invitedDepartmentIds ?? []}
                            setSelectedDeptartmentIds={(ids) =>
                                setUpdatedEvent({
                                    ...updatedEvent,
                                    invitedDepartmentIds: ids,
                                })
                            }
                        />
                        <Box p={2} pb={0}>
                            <UserSelect
                                id="individualInvitees"
                                users={template.allUsers ?? []}
                                label={translations("chooseIndividualInvitees")}
                                multiple={true}
                                value={updatedEvent.personallyInvitedUsers ?? []}
                                onChange={(_, value) =>
                                    value &&
                                    setUpdatedEvent({
                                        ...updatedEvent,
                                        personallyInvitedUsers: value,
                                    })
                                }
                            />
                        </Box>
                        <Box p={2}>
                            {/* TODO: Generalize the UserSelect component, and use it here as well */}
                            <Autocomplete
                                id="InvitedEntraGroupIds"
                                color="secondary"
                                noOptionsText={translations("form.noOptions")}
                                multiple={true}
                                options={template.entraIdGroups ?? []}
                                getOptionLabel={(option: IEntraIdGroup) => option?.name ?? ""}
                                isOptionEqualToValue={(option, value) =>
                                    option.objectId === value.objectId
                                }
                                value={
                                    (updatedEvent.invitedEntraGroupIds
                                        ?.map(
                                            (id) =>
                                                template.entraIdGroups?.find(
                                                    (g) => g.objectId === id
                                                ) ?? []
                                        )
                                        .filter((group) => !!group) as IEntraIdGroup[]) ?? []
                                }
                                onChange={(_, value) =>
                                    value &&
                                    setUpdatedEvent({
                                        ...updatedEvent,
                                        invitedEntraGroupIds: value.map((g) => g.objectId!),
                                    })
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        color="secondary"
                                        label={translations("chooseEntraGroupsInvitees")}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <MutationSnackbar
                isLoading={isLoading}
                hasError={!!error}
                isIdle={isIdle}
                successMessage={translations("saveSuccess")}
                errorMessage={translations("saveError")}
            />
        </Paper>
    );
};

interface FormErrorAlertProps {
    errors: ValidationResult;
}

const FormErrorsAlert = ({ errors }: FormErrorAlertProps) => (
    <Alert severity="error">
        {Object.values(errors).map((error) => (
            <Typography component={"p"} variant="body2" key={error}>
                {error}
            </Typography>
        ))}
    </Alert>
);
