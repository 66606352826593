import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "../../api";

export const useGetEventAttendeesListQuery = (eventId: string) => {
    const { attendanceClient } = useApiClient();

    return useQuery(["eventAttendeesList", eventId], async () => {
        return await attendanceClient.getAttendancesForEvent(eventId);
    });
};
