import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "../../api";

export const useAdminFutureEventsQuery = () => {
    const { adminClient } = useApiClient();

    return useQuery(["adminFutureEvents"], async () => {
        return await adminClient.adminGetEvents();
    });
};

export const useAdminPreviousEventsQuery = () => {
    const { adminClient } = useApiClient();

    return useQuery(["adminPreviousEvents"], async () => {
        return await adminClient.adminGetPreviousEvents();
    });
};

export const useAdminGetEventQuery = (eventId: string) => {
    const { adminClient } = useApiClient();

    return useQuery(["admin", eventId], async () => {
        return await adminClient.adminGetEvent(eventId);
    });
};
