import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    TextField,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { add } from "date-fns";
import no from "date-fns/locale/nb"; // For date time locale, TODO read from user preference?
import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { EventType, IEventDto, SimpleUser } from "../../../api";
import { RichTextEditor } from "../../RichTextEditor";
import { EventTypeSelect } from "../../ui/EventTypeSelect";
import { UserSelect } from "../../ui/UserSelect";
import { EventContext } from "../context";
import { eventValidationRules } from "./validation/eventValidationRules";
import { validateAndGetErrorMessage } from "./validation/formValidation";
import { FormFieldValue, ValidationResult } from "./validation/types";

interface Props {
    updatedEvent: IEventDto;
    eventType: EventType | undefined;
    setUpdatedEvent: Dispatch<SetStateAction<IEventDto>>;
    users?: SimpleUser[];
    errors: ValidationResult;
    setErrors: Dispatch<SetStateAction<ValidationResult>>;
    isCreating: boolean;
}

export const FormFields = ({
    updatedEvent,
    setUpdatedEvent,
    users,
    errors,
    setErrors,
    isCreating,
}: Props): JSX.Element => {
    const { translations, theme } = useContext(EventContext);

    useEffect(() => {
        if (!updatedEvent.startDateTime || !updatedEvent.endDateTime) {
            return;
        }

        revalidateAllInvalidFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedEvent.startDateTime, updatedEvent.endDateTime, updatedEvent.openingTimeForRegistration, updatedEvent.registrationDeadline]) // Update all validation when a date is changed

    useEffect(() => {
        // update end date when start date changes
        if (
            updatedEvent?.startDateTime &&
            updatedEvent?.endDateTime &&
            updatedEvent.startDateTime.getTime() > updatedEvent.endDateTime?.getTime()
        ) {
            setUpdatedEvent({
                ...updatedEvent,
                endDateTime: add(updatedEvent.startDateTime, { hours: 1 }),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedEvent.startDateTime]);

    useEffect(() => {
        // A temporary solution until "allowGuests" is read by the backend (https://github.com/Bouvet-deler/bouvet-events/issues/388)
        if (!updatedEvent?.allowGuests && updatedEvent.maxGuestsPerAttendee !== 0) {
            setUpdatedEvent({
                ...updatedEvent,
                maxGuestsPerAttendee: 0,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedEvent?.allowGuests]);

    const revalidateAllInvalidFields = () => {
        const updatedValidationResults: ValidationResult = {};

        Object.entries(errors).forEach(([fieldId, errorMessage]) => {
            if (!errorMessage) {
                return;
            }

            const errorMsg = validateAndGetErrorMessage(
                fieldId,
                updatedEvent[fieldId as keyof IEventDto] as FormFieldValue,
                updatedEvent,
                eventValidationRules
            );
            updatedValidationResults[fieldId] = errorMsg;
        });

        setErrors(updatedValidationResults);
    };

    const handleDateChange = (id: string, value: Date | null): void => {
        validateAndUpdateValue(id, value);
       

    };
    


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const id = event?.target.id;
        let value: string | number | boolean | Date = event.target.value;

        if (event?.target.type === "number" && event.target.value) {
            value = parseFloat(event.target.value);
        }

        if (event?.target.type === "checkbox") {
            value = event.target.checked;
        }

        validateAndUpdateValue(id, value);
    };

    const handleOpeningTimeChange =(event: React.ChangeEvent<HTMLInputElement>): void=> {
        const { id, checked } = event.target;

        

        if (id === 'isOpeningTime' && !checked) {
            setUpdatedEvent(prevState => ({
                ...prevState,
                openingTimeForRegistration: new Date(),
                [id]: checked,
            }));
        } else {
            setUpdatedEvent({
                ...updatedEvent,
                isOpeningTime: event.target.checked,
            });
        }
    };

    const validateAndUpdateValue = (id: string, value: FormFieldValue): void => {
        validateFieldAndSetError(id, value);
        setUpdatedEvent({
            ...updatedEvent,
            [id]: value,
        });
    };

    const validateFieldAndSetError = (fieldId: string, value: FormFieldValue) => {
        const errorMsg = validateAndGetErrorMessage(
            fieldId,
            value,
            updatedEvent,
            eventValidationRules
        );

        setErrors({ ...errors, [fieldId]: errorMsg });
    };

    const renderFormFieldError = (errorKey: string) =>
        errors[errorKey] && (
            <FormHelperText sx={{ color: "red" }}>
                * {errors[errorKey]?.split(":")[1]}
            </FormHelperText>
        );

    const handleRichTextChange = (htmlstring: string) => {
        validateAndUpdateValue("description", htmlstring);
    };

    const eventNameMaxLength = 100; // defined in the backend.

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <FormControl sx={{ width: "100%" }}>
                    <TextField
                        id="name"
                        variant="standard"
                        color="secondary"
                        label={translations("form.name")}
                        onChange={handleChange}
                        onBlur={() => validateFieldAndSetError("name", updatedEvent?.name)}
                        value={updatedEvent.name ?? ""}
                        error={!!errors?.name}
                        fullWidth
                        required
                        autoFocus
                        inputProps={{ maxLength: eventNameMaxLength }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {renderFormFieldError("name")}
                </FormControl>
                <FormHelperText>
                    {(updatedEvent?.name?.length ?? 0) + "/" + eventNameMaxLength}
                </FormHelperText>
            </Grid>

            <Grid item>
                <FormControl sx={{ width: "100%" }}>
                    <TextField
                        id="location"
                        variant="standard"
                        color="secondary"
                        label={translations("form.location")}
                        onChange={handleChange}
                        onBlur={revalidateAllInvalidFields}
                        value={updatedEvent.location ?? ""}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </FormControl>
            </Grid>

            <Grid item>
                <EventTypeSelect
                    id="eventType"
                    eventType={updatedEvent.eventType ?? EventType.Professional}
                    label={translations("form.eventCategory")}
                    multiple={false}
                    value={updatedEvent.eventType || EventType.Professional}
                    onChange={(_, value) =>
                        value && setUpdatedEvent({ ...updatedEvent, eventType: value })
                    }
                />
            </Grid>

            <Grid item>
                {users && (
                    <UserSelect
                        id="ownerId"
                        users={users}
                        label={translations("form.owner")}
                        multiple={false}
                        value={updatedEvent.owner ?? null}
                        onChange={(_, value) =>
                            value && setUpdatedEvent({ ...updatedEvent, owner: value })
                        }
                    />
                )}
            </Grid>

            <Grid item>
                {users && (
                    <UserSelect
                        id="collaborators"
                        users={users}
                        label={translations("form.collaborators")}
                        multiple={true}
                        value={updatedEvent.collaborators ?? []}
                        onChange={(_, value) =>
                            value &&
                            setUpdatedEvent({
                                ...updatedEvent,
                                collaborators: value,
                            })
                        }
                    />
                )}
            </Grid>

            <Grid item>
                <FormControl
                    sx={{
                        width: "100%",
                        minHeight: "20px",
                        borderBottom: "1px solid" + theme.palette.text.secondary,
                    }}
                >
                    <RichTextEditor
                        onChange={handleRichTextChange}
                        value={updatedEvent.description ?? ""}
                        placeholderText={translations("form.description")}
                    />
                </FormControl>
            </Grid>

            {/* TODO: Should we change date format based on language? SM 2023-08-04 */}
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={no}>
                <Grid item>
                    <DateTimePicker
                        sx={{ width: "100%" }}
                        value={updatedEvent.startDateTime}
                        disablePast
                        label={translations("form.startDateTime")}
                        onChange={(value: Date | null) => handleDateChange("startDateTime", value)}
                        slotProps={{
                            textField: {
                                variant: "standard",
                                color: "secondary",
                            },
                        }}
                    />
                    <FormHelperText> {errors?.startDateTime} </FormHelperText>
                </Grid>
                <Grid item>
                    <DateTimePicker
                        sx={{ width: "100%" }}
                        value={updatedEvent.endDateTime}
                        disablePast
                        minDateTime={updatedEvent.startDateTime}
                        label={translations("form.endDateTime")}
                        onChange={(value: Date | null) => handleDateChange("endDateTime", value)}
                        slotProps={{
                            textField: {
                                variant: "standard",
                                color: "secondary",
                            },
                        }}
                    />
                    {renderFormFieldError("endDateTime")}
                </Grid>
                <Grid item>
                    <DateTimePicker
                        sx={{ width: "100%" }}
                        value={updatedEvent.registrationDeadline}
                        disablePast={isCreating}
                        label={translations("form.registrationDeadline")}
                        onChange={(value: Date | null) =>
                            handleDateChange("registrationDeadline", value)
                        }
                        slotProps={{
                            textField: {
                                variant: "standard",
                                color: "secondary",
                            },
                        }}
                    />
                    {renderFormFieldError("registrationDeadline")}
                </Grid>
            </LocalizationProvider>

            <Grid item container spacing={2} sx={{ width: "100%" }}>
                <Grid item sx={{ mb: 1 }}>
                    <FormControlLabel
                        label={translations("form.isOpeningTime")}
                        control={
                            <Checkbox
                                id="isOpeningTime"
                                color="secondary"
                                onChange={handleOpeningTimeChange}
                                checked={updatedEvent.isOpeningTime}
                            />
                        }
                    />
                </Grid>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={no}>
                        <Grid item>
                            {updatedEvent.isOpeningTime && updatedEvent.openingTimeForRegistration && (
                                <DateTimePicker
                                    sx={{ width: "100%" }}
                                    disablePast
                                    label={translations("form.openingTimeForRegistration")}
                                    onChange={(value: Date | null) => handleDateChange("openingTimeForRegistration", value)}
                                    value={updatedEvent.openingTimeForRegistration ??  new Date()}
                                    slotProps={{ textField: { variant: "standard", color: "secondary" } }}
                                />
                            )}
                            {renderFormFieldError("openingTimeForRegistration")}
                        </Grid>
                    </LocalizationProvider>
            </Grid>



            <Grid item>
                <FormControl sx={{ width: "100%" }}>
                    <TextField
                        id="maxAttendees"
                        type="number"
                        variant="standard"
                        color="secondary"
                        label={translations("form.maxAttendees")}
                        onChange={handleChange}
                        onBlur={revalidateAllInvalidFields}
                        value={updatedEvent.maxAttendees ?? ""}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {renderFormFieldError("maxAttendees")}
                </FormControl>
            </Grid>

            <Grid item>
                <FormControl sx={{ width: "100%" }}>
                    <TextField
                        id="minAttendees"
                        type="number"
                        variant="standard"
                        color="secondary"
                        label={translations("form.minAttendees")}
                        onChange={handleChange}
                        onBlur={revalidateAllInvalidFields}
                        value={updatedEvent.minAttendees ?? ""}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {renderFormFieldError("minAttendees")}
                </FormControl>
            </Grid>

            <Grid item container spacing={2} sx={{ width: "100%" }}>
                <Grid item sx={{ mb: 1 }}>
                    <FormControlLabel
                        label={translations("form.allowGuests")}
                        control={
                            <Checkbox
                                id="allowGuests"
                                color="secondary"
                                onChange={handleChange}
                                checked={updatedEvent.allowGuests}
                            />
                        }
                    />
                </Grid>
                <Grid item>
                    {updatedEvent.allowGuests && (
                        <FormControl sx={{ width: "100%" }}>
                            <TextField
                                id="maxGuestsPerAttendee"
                                type="number"
                                variant="standard"
                                color="secondary"
                                label={translations("form.maxGuestsPerAttendee")}
                                onChange={handleChange}
                                value={updatedEvent.maxGuestsPerAttendee ?? 0}
                                fullWidth
                            />
                            {renderFormFieldError("maxGuestsPerAttendee")}
                        </FormControl>
                    )}
                </Grid>
            </Grid>
           

            
        </Grid> 
    );
};
