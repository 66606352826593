import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { appConfig } from "../appConfig";

export function configureLocalization(): void {
    i18n.use(Backend)
        .use(initReactI18next)
        .init({
            lng: appConfig.defaultLanguage,
            fallbackLng: appConfig.fallbackLanguage,
            supportedLngs: ["enUS", "nb"],
            ns: ["translation"],
            interpolation: {
                escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
            },
            returnNull: false,
            backend: {
                loadPath: "/locales/{{lng}}/{{ns}}.json",
            },
        });
}
