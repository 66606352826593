import { useQuery } from "@tanstack/react-query";
import { useApiClient } from "../../api";

export const useGetDietaryConsiderationsAsStringListForEventQuery = (eventId: string) => {
    const { attendanceReportClient } = useApiClient();
    return useQuery(["dietaryConsiderationsAsStringListForEvent", eventId], async () => {
        return await attendanceReportClient.getDietaryConsiderationsAsStringListForEvent(eventId);
    });
};

export const useGetAttendeeNamesAsString = (eventId: string, onlyAttending: boolean) => {
    const { attendanceReportClient } = useApiClient();
    return useQuery(["attendeeNamesAsString", eventId], async () => {
        return await attendanceReportClient.getAttendeeNamesAsString(eventId, onlyAttending);
    });
};
