import { CircularProgress, CircularProgressProps, LinearProgress, styled } from "@mui/material";
import { useEffect, useState } from "react";
import easter from "../../assets/pictures/scared-kong.gif";

const Wrapper = styled("div")(() => ({
    textAlign: "center",
    zIndex: 100,
    margin: "20%",
}));

const SpinnerInner = styled(CircularProgress)(() => ({
    display: "inline-block",
}));

export const SpinnerLargeCenter = (props: CircularProgressProps) => {
    const [spinner, setSpinner] = useState<number>(0);
    const [progress, setProgress] = useState<number>(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Wrapper>
            {spinner === 0 ?
                <SpinnerInner
                    size={100}
                    color={"secondary"}
                    {...props}
                    onClick={() => setSpinner(1)}
                />
            : spinner === 1 ?
                <LinearProgress
                    sx={{ height: "50px" }}
                    color={"secondary"}
                    {...props}
                    onClick={() => setSpinner(2)}
                    value={progress}
                />
            :   <img src={easter} onClick={() => setSpinner(0)} />}
        </Wrapper>
    );
};
