import { useContext } from "react";
import { SpinnerLargeCenter } from "../ui/Spinner";
import { EventForm } from "./Form";
import { EventContext, EventProvider } from "./context";
import { useGetCreateEventTemplateQuery } from "./queries";

const CreateEventInner = () => {
    const { data: template, isLoading, isError } = useGetCreateEventTemplateQuery();
    const { translations } = useContext(EventContext);
    document.title = translations("create.pageTitle") + " - Bouvet Events";

    if (isLoading) {
        return <SpinnerLargeCenter />;
    }

    if (!template?.eventDto || isError) {
        // return <ErrorTemplate />
        return <></>;
    }

    return <EventForm template={template!} isCreating={true} />;
};

export const CreateEvent = () => (
    <EventProvider>
        <CreateEventInner />
    </EventProvider>
);
