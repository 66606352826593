import { isFuture } from "date-fns";
import { useContext } from "react";
import { AttendanceDto, EventDto } from "../../api";
import { AttendanceModal } from "../AttendanceModal";
import { AttendanceModalEventInfo } from "../AttendanceModal/types";
import { LoggedInUserContext } from "./provider";
import { useGetLoggedInUserEventAttendanceQuery } from "./queries";

interface Props {
    event: AttendanceModalEventInfo;
    disableAttendanceRegistration: boolean;
    attendance?: AttendanceDto;
    eventDto: EventDto;
}

export const LoggedInUserAttendanceModal = ({ event, disableAttendanceRegistration, attendance, eventDto }: Props): JSX.Element => {
    const { name, image } = useContext(LoggedInUserContext)

    const enableQuery = !attendance;
    const { data } = useGetLoggedInUserEventAttendanceQuery(event?.eventId ?? "", enableQuery);
    attendance = !attendance ? data : attendance;

    const openingEventStatus = eventDto.openingTimeForRegistration ? !isFuture(eventDto.openingTimeForRegistration) : false;

    return (
        <>
            <AttendanceModal
                userName={name}
                userImage={image}
                event={event}
                isEventOpen={openingEventStatus}
                attendance={attendance}
                disableModal={disableAttendanceRegistration}
                isAdminOrOwnerEditing={false}
            />
        </>
    );
}
