import { useMutation } from "@tanstack/react-query";
import { SendEmailWithEvent, useApiClient } from "../../api";

export const useSendEmailQuery = () => {
    const { emailClient } = useApiClient();

    return useMutation(
        async (requestBody: SendEmailWithEvent) => {
            return await emailClient.send(requestBody);
        },
        {
            onSuccess: () => {
                console.log("Email sent");
            },
        }
    );
};
