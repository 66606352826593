import { EventInformation } from "../../../api";
import { getFormattedWhen } from "../../../shared-functions";
import { LinkRowData } from "../../ui/TableWithSearch";

export const generateAdminEventsTableData = (events: EventInformation[]): LinkRowData[] => {
    return events.flatMap((event) => {
        if (!event.eventId) {
            return [];
        }

        const id = event.eventId!;
        const rowHref = `/admin/event/${event.eventId}`;
        const formattedWhen = getFormattedWhen(
            event.startDateTime!,
            "nb",
            event.endDateTime!,
            false
        );

        return {
            key: id,
            href: rowHref,
            columns: [
                {
                    key: "name",
                    content: event.name ?? "",
                    sortableByColumn: false,
                },
                {
                    key: "location",
                    content: event.location ?? "N/A",
                    sortableByColumn: false,
                },
                {
                    key: "when",
                    content: formattedWhen ?? "",
                    sortableByColumn: false,
                },
                {
                    key: "numberOfParticipants",
                    content: event.numberOfParticipants ?? "",
                    sortableByColumn: false,
                },
            ],
        };
    });
};
