import CloseIcon from "@mui/icons-material/Close";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PeopleIcon from "@mui/icons-material/People";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    Fab,
    FormControlLabel,
    Modal,
    Tooltip,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { appConfig } from "../../config";
import { StyledModalInfoBox } from "../ui/styled-components";
import { AttendanceAllergyModal } from "./AttendanceAllergyModal";
import { useGetAttendeeNamesAsString } from "./queries";

interface AttendanceDownloadModalProps {
    eventId: string;
    isOwnerOrAdmin: boolean;
    isArchivedEvent: boolean;
}

export const AttendanceDownloadModal = ({
    eventId,
    isOwnerOrAdmin,
    isArchivedEvent,
}: AttendanceDownloadModalProps) => {
    const [open, setOpen] = useState(false);
    const [onlyAttending, setOnlyAttending] = useState(true);
    const [includeDietaryConsiderations, setIncludeDietaryConsiderations] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const translations = useTranslation("translation", {
        keyPrefix: "event",
    }).t;

    const apiBaseURL = appConfig.apiBaseURL;
    const urlToCSVFile =
        apiBaseURL +
        "/api/AttendanceReport/AsCsv?eventId=" +
        eventId +
        "&onlyAttending=" +
        onlyAttending.toString();
    const urlToPdfFile =
        apiBaseURL +
        "/api/AttendanceReport/AsPdf?eventId=" +
        eventId +
        "&onlyAttending=" +
        onlyAttending.toString();
    const urlToCSVFileWithDietaryPreferences =
        apiBaseURL + "/api/AttendanceReport/AsCsvWithSensitiveData?eventId=" + eventId;
    const urlToPdfFileWithDietaryPreferences =
        apiBaseURL + "/api/AttendanceReport/AsPdfWithSensitiveData?eventId=" + eventId;
    const { data: atendeesAsString } = useGetAttendeeNamesAsString(eventId, true);

    return (
        <>
            <Tooltip title={translations("details.download.participantInformation")}>
                <Fab color="primary" aria-label="Download" size="medium" onClick={handleOpen}>
                    <PeopleIcon fontSize="medium" color="secondary" />
                </Fab>
            </Tooltip>
            <Modal open={open} onClose={handleClose}>
                <StyledModalInfoBox sx={{ p: 2, px: 4 }}>
                    <Button onClick={handleClose} sx={{ float: "right" }} color="inherit">
                        <CloseIcon />
                    </Button>
                    <Typography component="h2" variant="h5" sx={{ mt: 2, mb: 1 }}>
                        {translations("details.download.participantInformation")}
                    </Typography>
                    <Box>
                        <FormControlLabel
                            label={translations("details.download.onlyAttending")}
                            control={
                                <Checkbox
                                    id="onlyAttending"
                                    color="secondary"
                                    onChange={() => setOnlyAttending(!onlyAttending)}
                                    checked={includeDietaryConsiderations || onlyAttending}
                                    disabled={includeDietaryConsiderations}
                                />
                            }
                        />
                    </Box>
                    {isOwnerOrAdmin && !isArchivedEvent && (
                        <Box>
                            <FormControlLabel
                                label={translations(
                                    "details.download.includeDietaryConsiderations"
                                )}
                                control={
                                    <Checkbox
                                        id="includeDietaryConsiderations"
                                        color="secondary"
                                        onChange={() =>
                                            setIncludeDietaryConsiderations(
                                                !includeDietaryConsiderations
                                            )
                                        }
                                        checked={includeDietaryConsiderations}
                                    />
                                }
                            />
                        </Box>
                    )}
                    <Box
                        display="flex"
                        alignItems={"center"}
                        mt={2}
                        ml={-5}
                        mr={-5}
                        flexDirection={"row"}
                        justifyContent={"center"}
                    >
                        <Button
                            sx={{ mr: 1, mb: 2, px: 2, width: "170px" }}
                            color="secondary"
                            variant="contained"
                            component={Link}
                            download={true}
                            target="_blank"
                            to={
                                includeDietaryConsiderations ?
                                    urlToCSVFileWithDietaryPreferences
                                :   urlToCSVFile
                            }
                        >
                            <FileDownloadIcon />
                            {translations("details.download.downloadAsCSV")}
                        </Button>
                        <Button
                            sx={{ mr: 1, mb: 2, width: "170px" }}
                            color="secondary"
                            variant="contained"
                            component={Link}
                            download={true}
                            target="_blank"
                            to={
                                includeDietaryConsiderations ?
                                    urlToPdfFileWithDietaryPreferences
                                :   urlToPdfFile
                            }
                        >
                            <PictureAsPdfIcon />
                            {translations("details.download.downloadAsPDF")}
                        </Button>
                    </Box>
                    <Divider />
                    <Box
                        display="flex"
                        alignItems={"center"}
                        mt={2}
                        ml={-5}
                        mr={-5}
                        flexDirection={"row"}
                        justifyContent={"center"}
                    >
                        <Button
                            sx={{ mr: 1, mb: 1, width: "170px" }}
                            size="medium"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    atendeesAsString?.join(", ") ?? "Ingen påmeldte"
                                );
                            }}
                        >
                            <CopyAllIcon />
                            {translations("details.download.copyAllAttendees")}
                        </Button>

                        {isOwnerOrAdmin && !isArchivedEvent && (
                            <AttendanceAllergyModal eventId={eventId} />
                        )}
                    </Box>
                </StyledModalInfoBox>
            </Modal>
        </>
    );
};
