import { Autocomplete, AutocompleteValue, TextField } from "@mui/material";
import { SyntheticEvent, useContext } from "react";
import { ISimpleUser } from "../../api";
import { EventContext } from "../Event/context";

type BaseProps<T, Multiple extends boolean> = {
    id: string;
    users: ISimpleUser[]; // TODO: Allow for other models than ISimpleUser, and when that is done, use this component for the AdGroupSelect as well
    label: string;
    multiple: Multiple;
    value: T;
    onChange: (_: SyntheticEvent, users: T) => void;
};

type Props<Multiple extends boolean> = BaseProps<
    AutocompleteValue<ISimpleUser, Multiple, false, false>,
    Multiple
>;

export const UserSelect = <Multiple extends boolean>(props: Props<Multiple>) => {
    const { translations } = useContext(EventContext);

    return (
        <Autocomplete
            id={props.id}
            color="secondary"
            noOptionsText={translations("form.noOptions")}
            multiple={props.multiple}
            options={props.users}
            getOptionLabel={(option: ISimpleUser) => option?.name ?? ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={props.value}
            onChange={props.onChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    color="secondary"
                    label={props.label}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            )}
        />
    );
};
