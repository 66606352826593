/// <reference types="vite-plugin-svgr/client" />

import { LockOpenOutlined, LockPersonOutlined, SettingsRounded } from "@mui/icons-material";
import { AppBar, Button, Container, IconButton, Tooltip, useTheme } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as DarkHeaderLogo } from "../../../assets/pictures/darkHeaderLogo.svg";
import { ReactComponent as LightHeaderLogo } from "../../../assets/pictures/lightHeaderLogo.svg";
import { LoggedInUserContext } from "../../LoggedInUser/provider";

interface Props {
    heightInPx: number;
}

export const Header = ({ heightInPx }: Props) => {
    const theme = useTheme();

    const translations = useTranslation("translation", {
        keyPrefix: "header",
    }).t;

    const { isAdmin } = useContext(LoggedInUserContext);

    return (
        <div style={{ flexGrow: 1 }}>
            <AppBar position="static" color="transparent" enableColorOnDark>
                {/* can add maxWidth 1280px for exacly the same container as current solution, the baseSize har been changed in MUI*/}
                <Container
                    sx={{
                        height: heightInPx + "px",
                        display: "flex",
                        alignItems: "center",
                        padding: "12px",
                        flexGrow: 1,
                        lineHeight: 0,
                        color: "transparent",
                    }}
                >
                    <Link
                        to="/"
                        style={{ flexGrow: 1, lineHeight: 0 }}
                        title={translations("home")}
                    >
                        {theme.palette.mode === "light" ?
                            <LightHeaderLogo style={{ width: "120px" }} />
                        :   <DarkHeaderLogo style={{ width: "120px" }} />}
                    </Link>

                    {isAdmin && <AdminButton />}

                    <Tooltip title={translations("settings")}>
                        <IconButton component={Link} to="settings">
                            {<SettingsRounded color="secondary" fontSize="large" />}
                        </IconButton>
                    </Tooltip>
                </Container>
            </AppBar>
        </div>
    );
};

const AdminButton = () => {
    const location = useLocation();
    const translations = useTranslation("translation", {
        keyPrefix: "header",
    }).t;

    const adminUrl = "/admin" + location.pathname;
    const userUrl = location.pathname.replace("/admin", "");
    const isOnAdminPage = location.pathname.indexOf("admin") >= 0;

    return (
        <>
            {isOnAdminPage ?
                <Button
                    color="secondary"
                    variant="contained"
                    component={Link}
                    to={userUrl}
                    sx={{ mx: 1 }}
                >
                    <LockOpenOutlined />
                    &nbsp;{translations("adminOverview")}
                </Button>
            :   <Button
                    color="primary"
                    variant="contained"
                    component={Link}
                    to={
                        (
                            !location.pathname.includes("/event") ||
                            location.pathname.includes("/event/create") ||
                            location.pathname.includes("/edit")
                        ) ?
                            "/admin"
                        :   adminUrl
                    }
                    sx={{ mx: 1 }}
                >
                    <LockPersonOutlined />
                    &nbsp;{translations("adminOverview")}
                </Button>
            }
        </>
    );
};
