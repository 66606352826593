import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PageWrapper } from "./PageWrapper";

interface Props {
    message?: string;
    title?: string;
}

export const GenericError = ({ message, title }: Props): JSX.Element => {
    const translations = useTranslation("translation", {
        keyPrefix: "error",
    }).t;

    return (
        <Box sx={{ textAlign: "center", mt: 8, p: "24px" }}>
            <Typography
                component={"h1"}
                variant="h4"
                sx={{
                    fontFamily: "'Courier', cursive, sans-serif",
                    color: "#747474",
                }}
            >
                {title ?? translations("anErrorHasOccurred")}
            </Typography>
            {message ?
                <Typography
                    component={"p"}
                    variant="h6"
                    sx={{ mt: 2, fontFamily: "'Courier', cursive, sans-serif" }}
                >
                    {message}
                </Typography>
            :   null}
            <Typography
                component={"p"}
                variant="h6"
                sx={{ mt: 2, fontFamily: "'Courier', cursive, sans-serif" }}
            >
                {translations("contactUs")}
                <a target="_blank" href="https://bouvet.slack.com/archives/C7NEEMRCM">
                    #minside_og_venner
                </a>
            </Typography>
        </Box>
    );
};

export const GenericErrorPage = ({ message, title }: Props): JSX.Element => {
    return (
        <PageWrapper>
            <GenericError message={message} title={title} />
        </PageWrapper>
    );
};
