import { useContext, useEffect, useState } from "react";
import { AttendanceStatus } from "../../../api";
import { SpinnerLargeCenter } from "../../ui/Spinner";
import { LinkRowData, TableWithSearch } from "../../ui/TableWithSearch";
import { EventOverviewContext } from "../context";
import { useFutureEventsQuery } from "../queries";
import { useEventTypeFilter } from "./filteringCategories";
import { generateFutureEventsTableData } from "./tableDataGenerator";

interface Props {
    owned: boolean;
    participating: boolean;
}

export const FutureEvents = ({ owned, participating }: Props) => {
    const { isDesktop, translations } = useContext(EventOverviewContext);
    const { data: events, isLoading } = useFutureEventsQuery(owned);

    const tableHeads =
        isDesktop ?
            [
                translations("name"),
                translations("location"),
                translations("when"),
                translations("deadline"),
                translations("respond"),
            ]
        :   [];

    const [rows, setRows] = useState<LinkRowData[]>([]);

    useEffect(() => {
        if (!events) return;

        const currentEvents =
            participating ?
                events.filter(
                    (e) =>
                        e.attendance?.attendanceStatus == AttendanceStatus.Attending ||
                        e.attendance?.attendanceStatus == AttendanceStatus.Waiting
                )
            :   events;

        const currentRows = generateFutureEventsTableData(currentEvents ?? [], isDesktop);
        setRows(currentRows);
    }, [participating, events, isDesktop]);

    const { filterComponents, filterCallback } = useEventTypeFilter();

    if (isLoading) {
        return <SpinnerLargeCenter />;
    }

    return (
        <TableWithSearch
            tableHeads={tableHeads}
            rows={rows}
            searchKeys={["name", "location", "startDateTime"]}
            extraFilterComponents={filterComponents}
            extraFilterCallback={filterCallback}
        />
    );
};
