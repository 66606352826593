import { useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useGetExistingEventQuery } from "../Event/queries";
import { GenericError } from "../error";
import { SpinnerLargeCenter } from "../ui/Spinner";
import { TableWithSearch } from "../ui/TableWithSearch";
import { useGetEventAttendeesListQuery } from "./queries";
import { generateTableData } from "./tableDataGenerator";

interface Props {
    eventId: string;
    userIsOwnerOrAdmin: boolean;
}

export const EventAttendanceTable = ({ eventId, userIsOwnerOrAdmin }: Props) => {
    const { data: attendees, isLoading } = useGetEventAttendeesListQuery(eventId);
    const { data: event, isLoading: eventIsLoading, isStale } = useGetExistingEventQuery(eventId);

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

    const translations = useTranslation("translation", {
        keyPrefix: "attendance",
    }).t;

    const isWaitingList = event?.isFull;

    let tableHeads;

    if (isDesktop) {
        tableHeads = [
            " ",
            translations("name"),
            translations("department"),
            translations("comment"),
            translations("answer"),
        ];
        if (event?.allowGuests)
            tableHeads.splice(tableHeads.length - 1, 0, translations("numberOfGuests"));
        if (isWaitingList)
            tableHeads.splice(tableHeads.length - 1, 0, translations("waitListSpot"));
        if (userIsOwnerOrAdmin) tableHeads.splice(tableHeads.length, 0, ""); // a column to edit the attendance for admin/owners
    } else {
        tableHeads = [translations("name"), translations("answer")];
    }

    if (isLoading || eventIsLoading) {
        return <SpinnerLargeCenter />;
    }

    if (isStale && !event) {
        return <GenericError message={"Det oppsto en feil ved uthenting av eventet"} />;
    }

    const rows = generateTableData(
        attendees ?? [],
        event!,
        userIsOwnerOrAdmin,
        isWaitingList,
        isDesktop
    );

    return (
        <TableWithSearch
            tableHeads={tableHeads}
            rows={rows}
            searchKeys={[
                "name",
                "department",
                "comment",
                "waitListSpot",
                "numberOfGuests",
                "answer",
            ]}
        />
    );
};
