import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { Box, Fab, Paper, Tooltip } from "@mui/material";
import { isPast } from "date-fns";
import { useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { AttendanceModalEventInfo } from "../AttendanceModal/types";
import { EmailModalEventInfo } from "../EmailModal/types";
import { EventAttendanceTable } from "../EventAttendanceTable";
import { EventDetailsActionButtons } from "../EventDetailsActionButtons";
import { LoggedInUserAttendanceModal } from "../LoggedInUser/LoggedInUserAttendanceModal";
import { LoggedInUserEmailModal } from "../LoggedInUser/LoggedInUserEmailModal";
import { LoggedInUserContext } from "../LoggedInUser/provider";
import { useGetLoggedInUserEventAttendanceQuery } from "../LoggedInUser/queries";
import { NotFound } from "../error";
import { EventInfo } from "../ui/Event/EventInfo";
import { SpinnerLargeCenter } from "../ui/Spinner";
import { EventContext, EventProvider } from "./context";
import { useGetExistingEventQuery } from "./queries";

interface Props {
    eventId: string;
}

export const EventDetailsRoute = () => {
    const { eventId } = useParams();

    if (!eventId) return <NotFound />;
    if (!eventId) {
        return <NotFound />;
    }

    return (
        <EventProvider>
            <EventDetails eventId={eventId} />
        </EventProvider>
    );
};

const EventDetails = ({ eventId }: Props) => {
    const loggedInUser = useContext(LoggedInUserContext);

    const { data: event, isLoading, isError } = useGetExistingEventQuery(eventId);
    const { data: loggedInUserAttendance } = useGetLoggedInUserEventAttendanceQuery(eventId);

    document.title = event?.name ? event?.name + " - Bouvet Events" : "Bouvet Events";

    if (isLoading) return <SpinnerLargeCenter />;

    if (!event?.id || isError) return <NotFound />;

    const disableAttendanceRegistration = (event.registrationDeadline ? isPast(event.registrationDeadline) && (loggedInUserAttendance?.attendanceStatus !== "Attending" && loggedInUserAttendance?.attendanceStatus !== "Waiting") : true) ||
        (event.openingTimeForRegistration ? !isPast(event.openingTimeForRegistration) : true);
    const isArchivedEvent = event.endDateTime ? isPast(event.endDateTime) : true;
    const userIsOwner = !!(
        event.owner?.id === loggedInUser?.id ||
        event?.collaborators?.some((c) => c.id === loggedInUser.id)
    );

    return (
        <>
            <Paper sx={{ p: 4, position: "relative" }}>
                <EventInfo event={event} />
                {!isArchivedEvent && (
                    <Box
                        sx={{ position: "absolute", right: 32, top: 32 }}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <LoggedInUserEmailModal
                            event={{ ...event, eventId: event.id } as EmailModalEventInfo}
                        />
                    </Box>
                )}

                {!isArchivedEvent && (
                    <Box mt={0}>
                        <LoggedInUserAttendanceModal event={{ ...event, eventId: event.id } as AttendanceModalEventInfo} disableAttendanceRegistration={disableAttendanceRegistration} eventDto={event} />
                    </Box>
                )}
                {loggedInUser?.isAdmin && <AdminFab />}
                <EventDetailsActionButtons eventId={eventId} isArchivedEvent={isArchivedEvent} isOwner={userIsOwner} />
            </Paper >
            <Paper sx={{ mt: "5px", padding: "16px", width: "auto", overflowX: "auto" }}>
                <EventAttendanceTable eventId={event.id!} userIsOwnerOrAdmin={userIsOwner} />
            </Paper>
        </>
    );
};

const AdminFab = () => {
    const { translations } = useContext(EventContext);
    const location = useLocation();
    const url = "/admin" + location.pathname;

    return (
        <Box sx={{ position: "absolute", right: 32, top: 32 }}>
            <Tooltip title={translations("details.goToAdminPage")}>
                <Fab
                    color="secondary"
                    aria-label="Admin"
                    size="medium"
                    sx={{ mb: 1 }}
                    component={Link}
                    to={url}
                >
                    <LockOpenOutlinedIcon />
                </Fab>
            </Tooltip>
        </Box>
    );
};
