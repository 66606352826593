import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface EmailFormCheckboxesProps {
    selectedStatus: string
    handleDropdownChange: (event: SelectChangeEvent<string>) => void;
}

const EmailFormDropdown: React.FC<EmailFormCheckboxesProps> = ({ selectedStatus, handleDropdownChange }) => {
    const translations = useTranslation("translation", {
        keyPrefix: "email",
    }).t;
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <FormControl sx={{ mt: 2.5, width: 300 }} size="small" >
                <InputLabel id="">{translations("recipients")}</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectedStatus}
                    label={translations("recipients")}
                    onChange={handleDropdownChange}
                    defaultValue="attendees"

                >
                    <MenuItem value={"attendees"}>{translations("attendees")}</MenuItem>
                    <MenuItem value={"waitlist"}>{translations("waitlist")}</MenuItem>
                    <MenuItem value={"attendeesAndWaitlist"}>{translations("attendeesAndWaitlist")}</MenuItem>
                </Select>

            </FormControl>
            <Typography sx={{ ml: 2, mt: 2.5 }} variant="body2" >{translations("emailInfoText")}</Typography></Box>

    );
};

export default EmailFormDropdown;
