import { PaletteMode } from "@mui/material";
import { createContext } from "react";

interface IColorModeContext {
    // currentMode: PaletteMode;
    toggleColorMode: () => void;
    setColorMode: (mode: PaletteMode) => void;
}

export const ColorModeContext = createContext<IColorModeContext>(undefined as never);
