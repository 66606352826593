import { IconButton, IconButtonProps, useTheme } from "@mui/material";

interface Props extends IconButtonProps {
    isActive?: boolean;
}

const ToolbarButton = ({ isActive, onClick, children }: Props) => {
    const theme = useTheme();

    return (
        <IconButton
            onClick={onClick}
            sx={{
                padding: "2px",
                margin: 0,
                borderRadius: 0,
                background: "none",
                color: theme.palette.text.primary,
                backgroundColor: isActive ? theme.palette.primary.main : undefined,
                "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                },
            }}
        >
            {children}
        </IconButton>
    );
};

export default ToolbarButton;
