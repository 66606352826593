import { Box, BoxProps, Button, ButtonProps, styled } from "@mui/material";

export const StyledResponseButton = styled(Button)<ButtonProps>(({ theme }) => ({
    height: "40px",
    width: "128px",
    [theme.breakpoints.down("md")]: {
        height: "32px",
        width: "164px"
    }
}))

export const StyledModalBox = styled(Box)<BoxProps>(({ theme }) => ({
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: "50%",
    maxWidth: "800px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 3, 2, 3),
    outline: "none",
    [theme.breakpoints.down("md")]: {
        width: "95%",
    },
}));

export const StyledModalInfoBox = styled(Box)<BoxProps>(({ theme }) => ({
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: "30%",
    maxHeight: "600px",
    maxWidth: "1000px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 3, 2, 3),
    outline: "none",
    overflowY: "hidden",
    overflowX: "hidden",
    [theme.breakpoints.down("md")]: {
        width: "95%",
    },
}));
