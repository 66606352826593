import { Email, Work } from "@mui/icons-material";
import {
    Box,
    Button,
    Container,
    FormControl,
    FormControlLabel,
    FormLabel,
    List,
    ListItem,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@mui/material";
import { ChangeEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { IUserPreferences, UserPreferences } from "../../api";
import { GenericError } from "../error";
import { MutationSnackbar } from "../ui/MutationSnackbar";
import { LoggedInUserContext } from "./provider";
import { useUserPreferenceMutation } from "./queries";

export const Preferences = () => {
    const translations = useTranslation("translation", {
        keyPrefix: "preferences",
    }).t;

    const loggedInUser = useContext(LoggedInUserContext);

    const [updatedDietaryConsiderations, setUpdatedDietaryConsiderations] = useState(
        loggedInUser?.preferences?.dietaryConsiderations ?? ""
    );

    const { mutate, isLoading, error, isIdle } = useUserPreferenceMutation();

    if (!loggedInUser) {
        return <GenericError />;
    }

    const handleUpdateLanguage = (_: ChangeEvent<HTMLInputElement>, value: string) => {
        const updatedPreferences: IUserPreferences = {
            ...loggedInUser.preferences,
            language: value,
        };
        mutate(updatedPreferences as UserPreferences);
    };

    const handleUpdateColorMode = (_: ChangeEvent<HTMLInputElement>, value: string) => {
        const updatedPreferences: IUserPreferences = {
            ...loggedInUser.preferences,
            darkMode: value === "dark",
        };
        mutate(updatedPreferences as UserPreferences);
    };

    const saveDietaryConsiderations = () => {
        const updatedPreferences: IUserPreferences = {
            ...loggedInUser.preferences,
            dietaryConsiderations: updatedDietaryConsiderations,
        };
        mutate(updatedPreferences as UserPreferences);
    };

    return (
        <Container maxWidth="sm">
            <Box sx={{ mt: 2, textAlign: "center" }}>
                <Typography component={"h1"} variant="h4">
                    {loggedInUser?.name}
                </Typography>
            </Box>
            <Box mt={2}>
                <List>
                    <ListItem>
                        <Email />
                        <Typography variant="body1" sx={{ ml: 1 }}>
                            {loggedInUser.email}
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Work />
                        <Typography variant="body1" sx={{ ml: 1 }}>
                            {loggedInUser.departmentName}
                        </Typography>
                    </ListItem>
                </List>
            </Box>
            <Typography component={"p"} variant="h6" sx={{ mt: 4 }}>
                {translations("title")}
            </Typography>
            <Box mt={2}>
                <FormControl>
                    <FormLabel color="secondary" id="choose-language">
                        {translations("language")}
                    </FormLabel>
                    <RadioGroup
                        aria-labelledby="choose-language"
                        value={loggedInUser?.preferences?.language ?? "nb"}
                        name="language-buttons"
                        onChange={handleUpdateLanguage}
                        row
                    >
                        <FormControlLabel
                            value="nb"
                            control={<Radio color="secondary" disabled={isLoading} />}
                            label={translations("norwegian")}
                        />
                        <FormControlLabel
                            value="enUS"
                            control={<Radio color="secondary" disabled={isLoading} />}
                            label={translations("english")}
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box mt={2}>
                <FormControl>
                    <FormLabel color="secondary" id="choose-color-mode">
                        {translations("colorMode")}
                    </FormLabel>
                    <RadioGroup
                        aria-labelledby="choose-color-mode"
                        value={loggedInUser?.preferences?.darkMode ? "dark" : "light"}
                        name="color-mode-buttons"
                        onChange={handleUpdateColorMode}
                        row
                    >
                        <FormControlLabel
                            value="dark"
                            control={<Radio color="secondary" disabled={isLoading} />}
                            label={translations("dark")}
                        />
                        <FormControlLabel
                            value="light"
                            control={<Radio color="secondary" disabled={isLoading} />}
                            label={translations("light")}
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box mt={2}>
                <FormControl sx={{ width: "100%", mb: 2 }}>
                    <TextField
                        disabled={isLoading}
                        label={translations("dietaryConsiderations")}
                        variant="standard"
                        color="secondary"
                        value={updatedDietaryConsiderations ?? ""}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                            setUpdatedDietaryConsiderations(e.target?.value)
                        }
                        multiline
                        InputLabelProps={{
                            shrink: true,
                        }}
                        helperText={`* ${translations("dietaryConsiderationsInformation")}`}
                    />
                </FormControl>
                <Button
                    disabled={
                        isLoading ||
                        loggedInUser?.preferences?.dietaryConsiderations ===
                            updatedDietaryConsiderations
                    }
                    color="secondary"
                    onClick={saveDietaryConsiderations}
                    variant="contained"
                >
                    {translations("saveDietaryConsiderations")}
                </Button>
            </Box>
            <MutationSnackbar
                isLoading={isLoading}
                hasError={!!error}
                isIdle={isIdle}
                successMessage={translations("saveSuccess")}
                errorMessage={translations("saveError")}
            />
        </Container>
    );
};
