import {
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    REMOVE_LIST_COMMAND,
} from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import {
    CodeOff,
    FormatAlignCenter,
    FormatAlignJustify,
    FormatAlignLeft,
    FormatAlignRight,
    FormatBold,
    FormatItalic,
    FormatListBulleted,
    FormatListNumbered,
    FormatStrikethrough,
    FormatUnderlined,
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import {
    $getSelection,
    $isRangeSelection,
    FORMAT_ELEMENT_COMMAND,
    FORMAT_TEXT_COMMAND,
    LexicalEditor,
    SELECTION_CHANGE_COMMAND,
} from "lexical";
import { useCallback, useEffect, useRef, useState } from "react";
import ToolbarButton from "../ToolbarButton";

const LowPriority = 1;
/*
const positionEditorElement = (editor: any, rect: any) => {
    if (rect === null) {
        editor.style.opacity = "0";
        editor.style.top = "-1000px";
        editor.style.left = "-1000px";
    } else {
        editor.style.opacity = "1";
        editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10
            }px`;
        editor.style.left = `${rect.left +
            window.pageXOffset -
            editor.offsetWidth / 2 +
            rect.width / 2
            }px`;
    }
}

interface FloatingLinkProps {
    editor: any
}

const FloatingLinkEditor = ({ editor }: FloatingLinkProps) => {
    const editorRef = useRef(null);
    const inputRef = useRef(null);
    const mouseDownRef = useRef(false);
    const [linkUrl, setLinkUrl] = useState<string>("");
    const [lastSelection, setLastSelection] = useState<RangeSelection | NodeSelection | GridSelection | null>();

    const updateLinkEditor = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent)) {
                setLinkUrl(parent.getURL());
            } else if ($isLinkNode(node)) {
                setLinkUrl(node.getURL());
            } else {
                setLinkUrl("");
            }
        }
        const editorElem = editorRef.current;
        const nativeSelection = window.getSelection();
        const activeElement = document.activeElement;

        if (editorElem === null) {
            return;
        }

        const rootElement = editor.getRootElement();
        if (
            selection !== null &&
            nativeSelection &&
            !nativeSelection.isCollapsed &&
            rootElement !== null &&
            rootElement.contains(nativeSelection.anchorNode)
        ) {
            const domRange = nativeSelection.getRangeAt(0);
            let rect;
            if (nativeSelection.anchorNode === rootElement) {
                let inner = rootElement;
                while (inner.firstElementChild != null) {
                    inner = inner.firstElementChild;
                }
                rect = inner.getBoundingClientRect();
            } else {
                rect = domRange.getBoundingClientRect();
            }

            if (!mouseDownRef.current) {
                positionEditorElement(editorElem, rect);
            }
            setLastSelection(selection);
        } else if (!activeElement || activeElement.className !== "link-input") {
            positionEditorElement(editorElem, null);
            setLastSelection(null);
            setLinkUrl("");
        }

        return true;
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }: any) => {
                editorState.read(() => {
                    updateLinkEditor();
                });
            }),

            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    updateLinkEditor();
                    return true;
                },
                LowPriority
            )
        );
    }, [editor, updateLinkEditor]);

    useEffect(() => {
        editor.getEditorState().read(() => {
            updateLinkEditor();
        });
    }, [editor, updateLinkEditor]);

    return (
        <div ref={editorRef} className="link-editor">
            <TextField
                ref={inputRef}
                sx={{ width: "100%" }}
                value={linkUrl}
                onChange={(event) => {
                    setLinkUrl(event.target.value);
                }}
                onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        event.preventDefault();
                        if (lastSelection !== null) {
                            if (linkUrl !== "") {
                                editor.dispatchCommand(
                                    TOGGLE_LINK_COMMAND,
                                    linkUrl
                                );
                            }
                        }
                    } else if (event.key === "Escape") {
                        event.preventDefault();
                    }
                }}
            />
        </div>
    );
}

const getSelectedNode = (selection: any) => {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
        return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
        return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    } else {
        return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
    }
}
*/

const formatBulletList = (
    editor: LexicalEditor,
    isBulletList: boolean,
    setIsBulletList: (value: boolean) => void
) => {
    if (!isBulletList) {
        editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
        setIsBulletList(true);
    } else {
        editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        setIsBulletList(false);
    }
};

const formatNumberedList = (
    editor: LexicalEditor,
    isNumberedList: boolean,
    setIsNumberedList: (value: boolean) => void
) => {
    if (!isNumberedList) {
        editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
        setIsNumberedList(true);
    } else {
        editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        setIsNumberedList(false);
    }
};

export const ToolbarPlugin = () => {
    const [editor] = useLexicalComposerContext();
    const toolbarRef = useRef(null);
    const [isBulletList, setIsBulletList] = useState(false);
    const [isNumberedList, setIsNumberedList] = useState(false);
    //const [isLink, setIsLink] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [isCode, setIsCode] = useState(false);

    const updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            // Update text format
            setIsBold(selection.hasFormat("bold"));
            setIsItalic(selection.hasFormat("italic"));
            setIsUnderline(selection.hasFormat("underline"));
            setIsStrikethrough(selection.hasFormat("strikethrough"));
            setIsCode(selection.hasFormat("code"));

            /*// Update links
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            } else {
                setIsLink(false);
            }*/
        }
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateToolbar();
                });
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    updateToolbar();
                    return false;
                },
                LowPriority
            )
        );
    }, [editor, updateToolbar]);
    /*
        const insertLink = useCallback(() => {
            if (!isLink) {
                editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
            } else {
                editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
            }
        }, [editor, isLink]);
    */

    return (
        <Stack direction="row" spacing={2} ref={toolbarRef} justifyContent="flex-start">
            <ToolbarButton
                isActive={isBold}
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
                }}
                aria-label="Format Bold"
            >
                <FormatBold fontSize="medium" />
            </ToolbarButton>
            <ToolbarButton
                isActive={isItalic}
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
                }}
                aria-label="Format Italics"
            >
                <FormatItalic fontSize="medium" />
            </ToolbarButton>
            <ToolbarButton
                isActive={isUnderline}
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
                }}
                aria-label="Format Underline"
            >
                <FormatUnderlined fontSize="medium" />
            </ToolbarButton>
            <ToolbarButton
                isActive={isStrikethrough}
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
                }}
                aria-label="Format Strikethrough"
            >
                <FormatStrikethrough fontSize="medium" />
            </ToolbarButton>
            <ToolbarButton
                isActive={isCode}
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code");
                }}
                aria-label="Insert Code"
            >
                <CodeOff fontSize="medium" />
            </ToolbarButton>

            {/* THIS BUTTON IS NOT WORKING AS JESPER WANTS IT TO, MUST FIX SO INPUT FIELD IS LIKE A TOOLTIP (DN, 27.09.23) 
            <ToolbarButton
                isActive={isLink}
                onClick={insertLink}
                aria-label="Insert Link"
            >
                <Link fontSize="medium" />
            </ToolbarButton>
            {
                isLink &&
                createPortal(
                    <FloatingLinkEditor editor={editor} />,
                    document.body
                )
            }*/}
            <ToolbarButton
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
                }}
                aria-label="Left Align"
            >
                <FormatAlignLeft fontSize="medium" />
            </ToolbarButton>
            <ToolbarButton
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
                }}
                aria-label="Center Align"
            >
                <FormatAlignCenter fontSize="medium" />
            </ToolbarButton>
            <ToolbarButton
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
                }}
                aria-label="Right Align"
            >
                <FormatAlignRight fontSize="medium" />
            </ToolbarButton>
            <ToolbarButton
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
                }}
                aria-label="Justify Align"
            >
                <FormatAlignJustify fontSize="medium" />
            </ToolbarButton>
            <ToolbarButton
                isActive={isBulletList}
                onClick={() => formatBulletList(editor, isBulletList, setIsBulletList)}
            >
                <FormatListBulleted fontSize="medium" />
            </ToolbarButton>
            <ToolbarButton
                isActive={isNumberedList}
                onClick={() => formatNumberedList(editor, isNumberedList, setIsNumberedList)}
            >
                <FormatListNumbered fontSize="medium" />
            </ToolbarButton>
        </Stack>
    );
};
