import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    SelectChangeEvent,
    TextField,
} from "@mui/material";
import { ChangeEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { EmailModalEventInfo, SelectOptions } from "./types";

import { ISendEmailRequest, IUser, SendEmailWithEvent } from "../../api";
import { useGetEventAttendeesListQuery } from "../EventAttendanceTable/queries";
import { RichTextEditor } from "../RichTextEditor";
import EmailFormDropdown from "./EmailFormDropdown";
import { useSendEmailQuery } from "./queries";

interface Props {
    event: EmailModalEventInfo;
    handleClose: () => void;
    loggedInUser: IUser;
}

export const SendEmailForm = ({ event, handleClose, loggedInUser }: Props): JSX.Element => {
    const translations = useTranslation("translation", {
        keyPrefix: "email",
    }).t;

    const [emailBody, setEmailBody] = useState("");
    const [emailSubject, setEmailSubject] = useState("");

    const sendEmailMutation = useSendEmailQuery();
    const handleBodyChange = (newBody: string): void => {
        setEmailBody(newBody);
        if (newBody.trim() !== "" && newBody !== "<p><br></p>") {
            setBodyErrorText("");
        }
    };

    const handleSubmit = async () => {
        if (!emailSubject) {
            setSubjectErrorText(translations("subjectError"));
        } else {
            setSubjectErrorText("");
        }
        if (emailBody === "<p><br></p>" || !emailBody) {
            setBodyErrorText(translations("bodyError"));
        } else {
            setBodyErrorText("");
        }

        if (!emailSubject || !emailBody) return;

        const emailRequest: ISendEmailRequest = {
            subject: emailSubject,
            body: emailBody,
        };

        const requestBody = new SendEmailWithEvent({
            emailRequest: emailRequest,
            userIds: filteredUserIds,
            eventId: event.eventId,
        });

        sendEmailMutation.mutate(requestBody, {
            onSuccess: () => {
                handleClose();
            },
            onError: (error) => {
                console.error("Error sending email", error);
            },
        });
    };

    const onSubjectChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setEmailSubject(e.target.value);
        setSubjectErrorText("");
    };

    const { data: attendees } = useGetEventAttendeesListQuery(event.eventId);

    const [selectedStatus, setSelectedStatus] = useState<SelectOptions>("attendees");
    const [subjectErrorText, setSubjectErrorText] = useState("");
    const [bodyErrorText, setBodyErrorText] = useState("");

    const handleDropdownChange = (event: SelectChangeEvent<string>) => {
        setSelectedStatus(event.target.value as SelectOptions);
    };

    const filteredUserIds = useMemo(() => {
        let result: string[] = [];
        result.push(loggedInUser.id as string);
        if (selectedStatus === "attendees" || selectedStatus === "attendeesAndWaitlist") {
            const attending =
                attendees
                    ?.filter(
                        (attendee) =>
                            attendee.attendanceStatus === "Attending" &&
                            attendee.userId !== undefined
                    )
                    .map((a) => a.userId as string) ?? [];
            result = [...result, ...attending];
        }
        if (selectedStatus === "waitlist" || selectedStatus === "attendeesAndWaitlist") {
            const waitlist =
                attendees
                    ?.filter(
                        (attendee) =>
                            attendee.attendanceStatus === "Waiting" && attendee.userId !== undefined
                    )
                    .map((a) => a.userId as string) ?? [];
            result = [...result, ...waitlist];
        }
        return result;
    }, [attendees, selectedStatus, loggedInUser?.id]);

    return (
        <>
            <TextField
                required
                id="standard-basic"
                label={translations("subject")}
                variant="standard"
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={onSubjectChange}
                error={!!subjectErrorText}
                helperText={subjectErrorText && `* ${subjectErrorText}`}
                placeholder={translations("subject")}
            />

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    cursor: "pointer",
                }}
            ></div>
            <FormControl
                sx={{
                    ml: 0.5,
                    width: "98%",
                    borderBottom: `1px solid ${bodyErrorText ? "rgba(255, 0, 0, 0.6)" : "rgba(0, 0, 0, 0.6)"}`,
                    transition: "border-bottom 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
                }}
            >
                <RichTextEditor
                    onChange={handleBodyChange}
                    placeholderText="Informasjon til deltakere"
                    value=""
                    height="100px"
                />
            </FormControl>
            <FormHelperText sx={{ ml: 0.5 }} error={!!bodyErrorText}>
                {bodyErrorText && `* ${bodyErrorText}`}
            </FormHelperText>

            <EmailFormDropdown
                selectedStatus={selectedStatus}
                handleDropdownChange={handleDropdownChange}
            />

            <Box sx={{ mt: 3, display: "flex" }}>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    {translations("send")}
                </Button>
                <Button variant="contained" color="secondary" sx={{ ml: 2 }} onClick={handleClose}>
                    {translations("cancel")}
                </Button>
            </Box>
        </>
    );
};
