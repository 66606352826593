import { useContext } from "react";
import { useParams } from "react-router-dom";
import { GenericError, NotFound } from "../error";
import { SpinnerLargeCenter } from "../ui/Spinner";
import { EventForm } from "./Form";
import { EventContext, EventProvider } from "./context";
import { useGetCopyEventTemplateQuery } from "./queries";

interface Props {
    eventId: string;
}

const CopyEventInner = ({ eventId }: Props) => {
    const { data: template, isLoading, isError } = useGetCopyEventTemplateQuery(eventId);
    const { translations } = useContext(EventContext);
    document.title = translations("copy.pageTitle") + " - Bouvet Events";
    if (isLoading) {
        return <SpinnerLargeCenter />;
    }

    if (!template?.eventDto || isError) {
        return <GenericError />;
    }

    return <EventForm template={template} isCreating={true} />;
};

export const CopyEvent = () => {
    const { eventId } = useParams();
    if (!eventId) {
        return <NotFound />;
    }

    return (
        <EventProvider>
            <CopyEventInner eventId={eventId} />
        </EventProvider>
    );
};
