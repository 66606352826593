import { Box, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const NotFound = () => {
    const translations = useTranslation("translation", {
        keyPrefix: "error",
    }).t;

    return (
        <Paper>
            <Box sx={{ textAlign: "left", mt: 8, padding: "24px" }}>
                <Typography
                    component={"h1"}
                    variant="h4"
                    sx={{
                        fontFamily: "'Courier', cursive, sans-serif",
                        color: "#747474",
                    }}
                >
                    <Typography
                        component={"span"}
                        variant="h2"
                        sx={{
                            fontFamily: "'Courier', cursive, sans-serif",
                            color: "#747474",
                        }}
                    >
                        404
                    </Typography>
                    :{translations("pageNotFound")}
                </Typography>
            </Box>
        </Paper>
    );
};
