import { useContext } from "react";
import { ApiClientContext } from "./apiClientContext";

export const useApiClient = () => {
    const ctx = useContext(ApiClientContext);

    if (!ctx) {
        throw new Error("ApiClientContext must be provided before use");
    }

    return ctx;
};
