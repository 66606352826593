import { Paper, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

export interface TabType {
    id: number;
    name: string;
    displayText: string;
    url: string;
}

interface EventsTabsProps {
    tabValue: number;
    tabs: TabType[];
}

export const StyledTabs = ({ tabValue, tabs }: EventsTabsProps) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

    const selectedTabColor =
        theme.palette.mode === "light" ?
            theme.palette.secondary.main // blue
        :   "#F9A86F"; // dark theme orange
    const tabMargin = isDesktop ? { marginRight: "8px" } : { marginBottom: "8px" };

    const StyledTab = (label: string, url: string) => (
        <Tab
            key={label}
            label={label}
            component={Link}
            to={url}
            sx={{ "&.Mui-selected": { color: selectedTabColor } }}
        />
    );

    return (
        <Paper sx={tabMargin}>
            <Tabs
                variant={isDesktop ? "standard" : "scrollable"}
                orientation={isDesktop ? "vertical" : "horizontal"}
                centered={isDesktop}
                // onChange={onChange}
                value={tabValue}
                textColor="inherit"
                color="secondary"
                TabIndicatorProps={{ style: { background: selectedTabColor } }}
            >
                {tabs.map((tab) => StyledTab(tab.displayText, tab.url))}
            </Tabs>
        </Paper>
    );
};
