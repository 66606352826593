import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SpinnerLargeCenter } from "../../ui/Spinner";
import { LinkRowData, TableWithSearch } from "../../ui/TableWithSearch";
import { useAdminFutureEventsQuery, useAdminPreviousEventsQuery } from "../queries";
import { generateAdminEventsTableData } from "./tableDataGenerator";

interface Props {
    isFutureEvent: boolean;
}

export const AdminEventTable = ({ isFutureEvent }: Props) => {
    const translations = useTranslation("translation", {
        keyPrefix: "admin",
    }).t;

    const futureEventsQuery = useAdminFutureEventsQuery;
    const previousEventsQuery = useAdminPreviousEventsQuery;

    const { data: events, isLoading } = isFutureEvent ? futureEventsQuery() : previousEventsQuery();

    const tableHeads = [
        translations("name"),
        translations("location"),
        translations("when"),
        translations("numberOfAttendees"),
    ];
    const [rows, setRows] = useState<LinkRowData[]>([]);

    useEffect(() => {
        if (!events) return;

        const currentRows = generateAdminEventsTableData(events);
        setRows(currentRows);
    }, [events]);

    if (isLoading) {
        return <SpinnerLargeCenter />;
    }

    return (
        <TableWithSearch
            tableHeads={tableHeads}
            rows={rows}
            searchKeys={["name", "location", "startDateTime"]}
        />
    );
};
