import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { EventType, IUserPreferences, UserPreferences } from "../../../api";
import { LoggedInUserContext } from "../../LoggedInUser/provider";
import { useUserPreferenceMutation } from "../../LoggedInUser/queries";
import { LinkRowData } from "../../ui/TableWithSearch";

export const useEventTypeFilter = () => {
    const translations = useTranslation("translation", {
        keyPrefix: "table",
    }).t;

    const loggedInUser = useContext(LoggedInUserContext);

    const { mutate } = useUserPreferenceMutation();

    const [eventTypeFilter, setEventTypeFilter] = useState(
        loggedInUser?.preferences?.eventTypePreferences?.map((elem) => elem as EventType) ?? []
    );

    const eventTypeMultiSelectFilter = (eventType: SelectChangeEvent<typeof EventType>) => {
        const {
            target: { value },
        } = eventType;
        setEventTypeFilter(value as unknown as EventType[]);
        saveEventTypePreferences(value as unknown as EventType[]);
    };

    const eventTypeCheckboxFilter = (eventType: EventType, checked: boolean) => {
        if (checked) {
            setEventTypeFilter([...eventTypeFilter, eventType]);
            saveEventTypePreferences([...eventTypeFilter, eventType]);
        } else {
            setEventTypeFilter(eventTypeFilter.filter((e) => e !== eventType));
            saveEventTypePreferences(eventTypeFilter.filter((e) => e !== eventType));
        }
    };

    const saveEventTypePreferences = (value: EventType[]) => {
        const updatedPreferences: IUserPreferences = {
            ...loggedInUser.preferences,
            eventTypePreferences: value,
        };
        mutate(updatedPreferences as UserPreferences);
    };

    const filterCallback = useMemo(
        () => (row: LinkRowData) =>
            eventTypeFilter.length === 0 || eventTypeFilter.includes(row.eventType!),
        [eventTypeFilter]
    );

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const filterComponents = (
        <>
            {isMobile ?
                <FormControl variant="outlined" color="secondary" size="small" sx={{ width: 150 }}>
                    <InputLabel id="event-type-select-filtering-label">
                        {translations("eventCategories")}
                    </InputLabel>
                    <Select
                        labelId="event-type-select-filtering-label"
                        label={translations("eventCategories")}
                        multiple
                        value={eventTypeFilter as unknown as typeof EventType}
                        input={<OutlinedInput label={translations("eventCategories")} />}
                        renderValue={(selected) =>
                            (selected as unknown as EventType[])
                                .map((eventType) =>
                                    translations(`eventTypes.${eventType.toLocaleLowerCase()}`)
                                )
                                .join(", ")
                        }
                        onChange={eventTypeMultiSelectFilter}
                    >
                        {Object.values(EventType).map((eventType) => (
                            <MenuItem key={eventType} value={eventType}>
                                <Checkbox
                                    checked={eventTypeFilter.indexOf(eventType) > -1}
                                    sx={{ ml: -2 }}
                                />
                                <ListItemText
                                    primary={translations(
                                        `eventTypes.${eventType.toLocaleLowerCase()}`
                                    )}
                                />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            :   <FormGroup row>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 0.68 }}>
                        {translations("eventCategories")}:{" "}
                    </Typography>
                    <FormControlLabel
                        control={<Checkbox color="secondary" />}
                        checked={eventTypeFilter.includes(EventType.Professional)}
                        label={translations("eventTypes.professional")}
                        onChange={(value) =>
                            eventTypeCheckboxFilter(
                                EventType.Professional,
                                (value.target as HTMLInputElement).checked
                            )
                        }
                        sx={{ ml: 0.1 }}
                    />
                    <FormControlLabel
                        control={<Checkbox color="secondary" />}
                        checked={eventTypeFilter.includes(EventType.Social)}
                        label={translations("eventTypes.social")}
                        onChange={(value) =>
                            eventTypeCheckboxFilter(
                                EventType.Social,
                                (value.target as HTMLInputElement).checked
                            )
                        }
                    />
                    <FormControlLabel
                        control={<Checkbox color="secondary" />}
                        checked={eventTypeFilter.includes(EventType.Sports)}
                        label={translations("eventTypes.sports")}
                        onChange={(value) =>
                            eventTypeCheckboxFilter(
                                EventType.Sports,
                                (value.target as HTMLInputElement).checked
                            )
                        }
                    />
                </FormGroup>
            }
        </>
    );

    return {
        filterComponents,
        filterCallback,
    };
};
