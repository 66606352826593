import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Fab, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AttendanceDownloadModal } from "./AttendanceDownloadModal";

interface Props {
    eventId: string;
    isArchivedEvent: boolean;
    isAdmin?: boolean;
    isOwner?: boolean;
}

export const EventDetailsActionButtons = ({
    eventId,
    isArchivedEvent,
    isAdmin = false,
    isOwner = false,
}: Props) => {
    const translations = useTranslation("translation", {
        keyPrefix: "event",
    }).t;

    return (
        <Box
            sx={{ position: "absolute", right: 32, bottom: 32 }}
            display={"flex"}
            flexDirection={"column"}
        >
            <AttendanceDownloadModal
                eventId={eventId}
                isOwnerOrAdmin={isAdmin || isOwner}
                isArchivedEvent={isArchivedEvent}
            />
            <Tooltip title={translations("details.copyEvent")}>
                <Fab
                    sx={{ mt: 1 }}
                    color="primary"
                    aria-label="Copy"
                    size="medium"
                    component={Link}
                    to={`/event/${eventId}/copy`}
                >
                    <ContentCopyIcon />
                </Fab>
            </Tooltip>

            {(isAdmin || isOwner) && !isArchivedEvent && (
                <Tooltip title={translations("details.editEvent")}>
                    <Fab
                        sx={{ mt: 1 }}
                        color="secondary"
                        aria-label="Edit"
                        size="medium"
                        component={Link}
                        to={`/event/${eventId}/edit`}
                    >
                        <EditIcon />
                    </Fab>
                </Tooltip>
            )}
        </Box>
    );
};
