import { Autocomplete, AutocompleteValue, TextField } from "@mui/material";
import { SyntheticEvent, useContext } from "react";
import { EventType } from "../../api";
import { EventContext } from "../Event/context";

type BaseProps<T, Multiple extends boolean> = {
    id: string;
    eventType: EventType;
    label: string;
    multiple: Multiple;
    value: T;
    onChange: (_: SyntheticEvent, eventType: T) => void;
};

type Props<Multiple extends boolean> = BaseProps<
    AutocompleteValue<EventType, Multiple, true, false>,
    Multiple
>;

export const EventTypeSelect = <Multiple extends boolean>(props: Props<Multiple>) => {
    const { translations } = useContext(EventContext);

    return (
        <Autocomplete
            id={props.id}
            color="secondary"
            noOptionsText={translations("form.noOptions")}
            multiple={props.multiple}
            options={Object.values(EventType)}
            getOptionLabel={(option) => translations(`form.eventTypes.${option.toLowerCase()}`)}
            value={props.value}
            onChange={props.onChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    color="secondary"
                    label={props.label}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            )}
        />
    );
};
