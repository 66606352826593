import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { appConfig } from "../appConfig";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: appConfig.appInsightsConnectionString,
        extensions: [reactPlugin],
        enableAutoRouteTracking: true,
        enableDebug: true,
    },
});

export { appInsights, reactPlugin };
