import { Avatar } from "@mui/material";
import { getInitials } from "../../shared-functions";

interface Props {
    userName?: string;
    image?: string;
}

export const UserAvatar = ({ userName, image }: Props) => {
    if (image) {
        return <Avatar src={`data:image/jpeg;base64,${image}`} />;
    }

    if (userName) {
        <Avatar alt={userName}>{getInitials(userName)}</Avatar>;
    }

    return <Avatar>{""}</Avatar>;
};
