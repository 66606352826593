import { useContext } from "react";
import { useParams } from "react-router-dom";
import { GenericError, NotFound } from "../error";
import { SpinnerLargeCenter } from "../ui/Spinner";
import { EventForm } from "./Form";
import { EventContext, EventProvider } from "./context";
import { useGetEditEventTemplateQuery } from "./queries";

interface Props {
    eventId: string;
}

const EditEventInner = ({ eventId }: Props) => {
    const { data: template, isLoading, isError } = useGetEditEventTemplateQuery(eventId);
    const { translations } = useContext(EventContext);
    document.title = translations("edit.pageTitle") + " - Bouvet Events";

    if (isLoading) {
        return <SpinnerLargeCenter />;
    }

    if (!template?.eventDto || isError) {
        return <GenericError />;
    }

    return <EventForm template={template} isCreating={false} />;
};

export const EditEvent = () => {
    const { eventId } = useParams();
    if (!eventId) {
        return <NotFound />;
    }

    return (
        <EventProvider>
            <EditEventInner eventId={eventId} />
        </EventProvider>
    );
};
