import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { EventContext } from "../context";
import { useDeleteEventQuery } from "../queries";

interface Props {
    isLoading: boolean;
    hasError: boolean;
    isCreating: boolean;
    eventId: string;
}

export const EventFormButtons = ({
    isLoading,
    hasError,
    isCreating,
    eventId,
}: Props): JSX.Element => {
    const { translations } = useContext(EventContext);
    const {
        mutate: deleteMutate,
        isSuccess,
        isError,
        isLoading: deleteIsLoading,
    } = useDeleteEventQuery(eventId);
    const navigate = useNavigate();

    useEffect(() => {
        if (isSuccess) navigate("/");
    }, [isSuccess, navigate]);

    useEffect(() => {
        if (isError) alert(translations("deleteError"));
    }, [isError, translations]);

    return (
        <>
            <LoadingButton
                loading={isLoading}
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isLoading || hasError}
            >
                {isCreating ? translations("form.create") : translations("form.save")}
            </LoadingButton>
            {!isCreating && (
                <LoadingButton
                    loading={deleteIsLoading}
                    variant="contained"
                    sx={{ ml: 2 }}
                    disabled={isLoading || deleteIsLoading}
                    onClick={() =>
                        window.confirm(translations("form.areYouSure")) && deleteMutate()
                    }
                >
                    {translations("form.deleteEvent")}
                </LoadingButton>
            )}
            <Button variant="contained" sx={{ ml: 2 }} disabled={isLoading} component={Link} to="/">
                {translations("form.cancel")}
            </Button>
        </>
    );
};
