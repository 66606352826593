// import { AppInsightsContext, withAITracking } from "@microsoft/applicationinsights-react-js";
import { PaletteMode, ThemeProvider } from "@mui/material";
import { Suspense, useMemo, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./Routes";
import { ApiClientProvider, ReactQueryProvider } from "./api";
import { ColorModeContext, Theme, configureLocalization } from "./config";
import { LoggedInUserProvider } from "./features/LoggedInUser/provider";

// appInsights.loadAppInsights();

const App = (): JSX.Element => {
    configureLocalization();

    const [mode, setMode] = useState<PaletteMode>("light");

    const colorMode = useMemo(() => {
        return {
            setColorMode: (newMode: PaletteMode) => {
                if (mode !== newMode) {
                    setMode(newMode);
                }
            },
            toggleColorMode: () => {
                setMode((prevMode: PaletteMode) => (prevMode === "light" ? "dark" : "light"));
            },
        };
    }, [mode]);

    const theme = Theme(mode);

    return (
        <BrowserRouter>
            <ReactQueryProvider>
                <ApiClientProvider>
                    <ColorModeContext.Provider value={colorMode}>
                        <ThemeProvider theme={theme}>
                            <LoggedInUserProvider>
                                <Suspense>
                                    {" "}
                                    {/* <Suspense> necessary for the useTranslation hook when the theme changes. Not sure why. Feel free to figure it out. <3 (MG, 21/7/23)  */}
                                    <Routes />
                                </Suspense>
                            </LoggedInUserProvider>
                        </ThemeProvider>
                    </ColorModeContext.Provider>
                </ApiClientProvider>
            </ReactQueryProvider>
        </BrowserRouter>
    );
};

// export default withAITracking(reactPlugin, App);
export default App;
