import { Container, CssBaseline } from "@mui/material";
import { Outlet } from "react-router-dom";

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ColorModeContext, appConfig } from "../../../config";
import { LoggedInUserContext } from "../../LoggedInUser/provider";
import { SpinnerLargeCenter } from "../Spinner";
import { Footer } from "./Footer";
import { Header } from "./Header";

export const PageLayout = () => {
    const { preferences } = useContext(LoggedInUserContext);
    const { setColorMode } = useContext(ColorModeContext);
    const { i18n } = useTranslation();

    const language = preferences?.language ?? appConfig.defaultLanguage;

    if (i18n.language !== language) {
        i18n.changeLanguage(language);
    }

    if (!preferences) {
        return <SpinnerLargeCenter />;
    }

    const userColorMode = preferences.darkMode ? "dark" : "light";
    setColorMode(userColorMode); // If it is in a useEffect it stucks in a loop. Anyone please explain to me why? ♥ (MG 27/7/23)

    const headerHeightInPx = 72;
    const footerHeightInPx = 52;
    const windowHeight = window.innerHeight;
    const containerHeight = windowHeight - headerHeightInPx - footerHeightInPx;

    return (
        <>
            <CssBaseline />
            <Header heightInPx={headerHeightInPx} />
            <Container
                sx={{
                    padding: "8px",
                    position: "relative",
                    minHeight: containerHeight + "px",
                }}
                maxWidth="lg"
            >
                <Outlet />
            </Container>
            <Footer heightInPx={footerHeightInPx} />
        </>
    );
};
