import {
    Box,
    Link as MuiLink,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IUserPreferences, UserPreferences } from "../../../api";
import { LoggedInUserContext } from "../../LoggedInUser/provider";
import { useUserPreferenceMutation } from "../../LoggedInUser/queries";
import { getComparator, stableSort } from "./functions";
import { ColumnData, LinkRowData, Order } from "./types";

interface EventsTableRenderProps {
    tableHeads?: string[];
    rows: LinkRowData[];
}

// Only rendering and pagination. Used by future events and previous events and my events
export const EventsTableRender = ({ tableHeads, rows }: EventsTableRenderProps) => {
    const translations = useTranslation("translation", {
        keyPrefix: "table",
    }).t;

    const loggedInUser = useContext(LoggedInUserContext);
    const { mutate } = useUserPreferenceMutation();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(
        loggedInUser?.preferences?.numberOfRowsPerPage ?? 25
    );
    const [displayedRows, setDisplayedRows] = useState<LinkRowData[]>([]);
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState<string>("");

    const pageCount = useMemo(
        () => Math.ceil(rows.length / rowsPerPage),
        [rows.length, rowsPerPage]
    );

    const saveNumberOfRowsPerPage = (value: number) => {
        const updatedPreferences: IUserPreferences = {
            ...loggedInUser.preferences,
            numberOfRowsPerPage: value,
        };
        mutate(updatedPreferences as UserPreferences);
    };

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(() => {
            const updatedValue = parseInt(event.target.value);
            saveNumberOfRowsPerPage(updatedValue);
            return updatedValue;
        });

        setPage(0);
    };

    const handleRequestSort = (property: string) => () => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    useEffect(() => {
        const sortedRows = stableSort(rows, getComparator(order, orderBy ?? ""));
        const newDisplayedRows = sortedRows.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
        setDisplayedRows(newDisplayedRows);
    }, [rows, page, rowsPerPage, order, orderBy]);

    useEffect(() => {
        if (page >= pageCount) {
            setPage(0);
        }
    }, [page, pageCount]);

    const columnKeys = useMemo(() => {
        if (rows.length === 0 || !rows[0]?.columns) {
            return [];
        }
        return rows[0].columns.map((column) => column.key);
    }, [rows]);

    const sortableColumnKeys = useMemo(() => {
        if (rows.length === 0 || !rows[0]?.columns) {
            return [];
        }
        return rows[0].columns
            .filter((column) => column.sortableByColumn)
            .map((column) => column.key);
    }, [rows]);

    return (
        <>
            {/* The size property of the table will not work as the padding is moved to links */}
            <Table>
                {tableHeads && (
                    <TableHead>
                        <TableRow>
                            {tableHeads.map((heading, index) => (
                                <TableCell
                                    key={heading}
                                    sx={{
                                        width: `${100 / tableHeads.length - 1}%`,
                                    }}
                                    sortDirection={orderBy === columnKeys[index] ? order : false}
                                >
                                    {sortableColumnKeys.find((key) => key === columnKeys[index]) ?
                                        <TableSortLabel
                                            active={orderBy === columnKeys[index]}
                                            direction={
                                                orderBy === columnKeys[index] ? order : "asc"
                                            }
                                            onClick={handleRequestSort(columnKeys[index])}
                                        >
                                            <Typography sx={{ fontWeight: "bold" }}>
                                                {heading}
                                                {orderBy === columnKeys[index] ?
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {order === "desc" ?
                                                            translations("desc")
                                                        :   translations("asc")}
                                                    </Box>
                                                :   null}
                                            </Typography>
                                        </TableSortLabel>
                                    :   <Typography sx={{ fontWeight: "bold" }}>
                                            {heading}
                                        </Typography>
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                )}
                <TableBody>
                    {displayedRows.map((rowData) => (
                        <TableRow
                            key={rowData.key}
                            sx={{
                                cursor: rowData.href ? "pointer" : "default",
                                "&:has(a:focus)": {
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    outline: "auto",
                                },
                            }}
                            hover={!!rowData.href}
                        >
                            {rowData.columns.map((col, index) => {
                                return (
                                    <EventTableTableCell
                                        href={rowData.href}
                                        col={col}
                                        key={rowData.key + col.key}
                                        cellIndex={index}
                                    />
                                );
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                component="nav"
                rowsPerPageOptions={[25, 50, 100, 200, 1000]}
                count={rows?.length ?? 0}
                rowsPerPage={rowsPerPage}
                page={page >= pageCount ? 0 : page}
                SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} ${translations("of")} ${count}`
                }
                labelRowsPerPage={translations("rowsPerPage")}
            />
        </>
    );
};

interface TableCellProps {
    href?: string;
    col: ColumnData;
    cellIndex: number;
}

const EventTableTableCell = ({ href, col, cellIndex }: TableCellProps) => {
    const contentIsString = typeof col.content === "string";

    const getColInner = (): JSX.Element =>
        contentIsString ?
            <Typography variant="body1" sx={{ wordBreak: "break-word" }}>
                {col.content}
            </Typography>
        :   <>{col.content}</>;

    if (col.shouldNotLink || !href) {
        return <TableCell>{getColInner()}</TableCell>;
    }

    return (
        <TableCell sx={{ padding: 0, height: "1px" }}>
            <MuiLink
                sx={{
                    display: "flex",
                    padding: "1rem",
                    alignItems: "center",
                    minHeight: "100%",
                    "&:focus": { outline: "none" },
                }}
                component={Link}
                to={href}
                color="inherit"
                tabIndex={cellIndex === 0 ? 0 : -1}
                underline="none"
            >
                {getColInner()}
            </MuiLink>
        </TableCell>
    );
};
