import { Alert, Snackbar } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

interface Props {
    isLoading: boolean;
    hasError: boolean;
    isIdle: boolean;
    errorMessage: string;
    successMessage: string;
}

export const MutationSnackbar = ({
    isLoading,
    hasError,
    isIdle,
    errorMessage,
    successMessage,
}: Props) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [saveFailed, setSaveFailed] = useState(true);

    const handleError = useCallback(() => {
        if (isIdle) {
            return;
        }

        if (isLoading) {
            setSnackbarOpen(false);
            return;
        }

        setSaveFailed(!!hasError);
        setSnackbarOpen(true);
    }, [isLoading, hasError, isIdle]);

    useEffect(() => {
        handleError();
    }, [handleError]);

    return (
        <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}
        >
            <Alert
                onClose={() => setSnackbarOpen(false)}
                severity={saveFailed ? "error" : "success"}
                sx={{ width: "100%" }}
            >
                {saveFailed ? errorMessage : successMessage}
            </Alert>
        </Snackbar>
    );
};
