import { useMediaQuery, useTheme } from "@mui/material";
import { TFunction } from "i18next";
import { createContext, memo } from "react";
import { useTranslation } from "react-i18next";

export interface EventOverviewContextProps {
    isDesktop: boolean;
    translations: TFunction<"translation", "eventOverview">;
}

export const EventOverviewContext = createContext<EventOverviewContextProps>(undefined as never);

interface Props {
    children: React.ReactNode;
}

export const EventOverviewProviderComponent = ({ children }: Props): JSX.Element => {
    const translations = useTranslation("translation", {
        keyPrefix: "eventOverview",
    }).t;

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

    return (
        <EventOverviewContext.Provider
            value={{
                isDesktop,
                translations,
            }}
        >
            {children}
        </EventOverviewContext.Provider>
    );
};

export const EventOverviewProvider = memo(EventOverviewProviderComponent);
