import { Stack, Typography } from "@mui/material";

interface Props {
    title: string;
    icon: JSX.Element;
    info?: string | number;
}
export const InfoLine = (props: Props) => {
    return (
        <Stack direction="row" spacing={1} sx={{ mt: 1, mb: 2 }}>
            {props.icon}
            <Typography variant="body1" sx={{ typography: { fontWeight: 750 } }}>
                {props.title}:
            </Typography>
            <Typography variant="body1">{props.info}</Typography>
        </Stack>
    );
};
