import AddIcon from "@mui/icons-material/Add";
import { Box, Fab, Grid, Paper } from "@mui/material";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { StyledTabs, TabType } from "../ui/Tabs";
import { FutureEvents } from "./EventsTable/FutureEvents";
import { PreviousEvents } from "./EventsTable/PreviousEvents";
import { EventOverviewContext, EventOverviewProvider } from "./context";

const tabKeys = [
    "upcomingEvents",
    "participations",
    "previousEvents",
    "yourUpcomingEvents",
    "yourPreviousEvents",
];

interface Props {
    currentTabValue?: number;
}

const EventsOverviewInner = ({ currentTabValue = 0 }: Props) => {
    const { translations } = useContext(EventOverviewContext);

    const tabs: TabType[] = tabKeys.map((key, index) => {
        return {
            id: index,
            name: key,
            displayText: translations(key),
            url: `/${key}`,
        };
    });

    const isFutureEvent =
        tabs[currentTabValue].name == "upcomingEvents" ||
        tabs[currentTabValue].name == "participations" ||
        tabs[currentTabValue].name == "yourUpcomingEvents";

    return (
        <>
            <Grid container>
                <Grid item lg={2} xs={12}>
                    <StyledTabs tabValue={currentTabValue} tabs={tabs} />
                </Grid>
                <Grid item lg={10} xs={12}>
                    <Paper
                        sx={{
                            padding: "16px",
                            width: "auto",
                            overflowX: "auto",
                            marginBottom: "1rem",
                        }}
                    >
                        {isFutureEvent ?
                            <FutureEvents
                                owned={tabs[currentTabValue].name == "yourUpcomingEvents"}
                                participating={tabs[currentTabValue].name == "participations"}
                            />
                        :   <PreviousEvents
                                owned={tabs[currentTabValue].name == "yourPreviousEvents"}
                            />
                        }
                    </Paper>
                </Grid>
            </Grid>
            <CreateEventFab />
        </>
    );
};

export const EventsOverview = (props: Props) => {
    document.title = "Bouvet Events";

    return (
        <EventOverviewProvider>
            <EventsOverviewInner {...props} />
        </EventOverviewProvider>
    );
};

const CreateEventFab = () => {
    return (
        <Box
            sx={{
                position: "sticky",
                width: "100%",
                height: "5rem",
                bottom: 0,
            }}
        >
            <Fab
                sx={{
                    position: "absolute",
                    right: "1rem",
                    top: "0.5rem",
                }}
                color="secondary"
                aria-label="Create"
                size="large"
                component={Link}
                to="/event/create"
            >
                <AddIcon />
            </Fab>
        </Box>
    );
};
