import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import { isFuture } from "date-fns";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AttendanceDto, AttendanceStatus, IAttendanceDto } from "../../api";
import { LoggedInUserContext } from "../LoggedInUser/provider";
import { AttendanceModalEventInfo } from "./types";

interface Props {
    newUserAttendance?: IAttendanceDto;
    event: AttendanceModalEventInfo;
    formHasError: boolean;
    handleAttendanceRegistration: (userAttendance: AttendanceDto) => void;
    mutationIsLoading?: boolean;
    loggedInUserIsOwnerOrCollaborator: boolean;
    loggedInUserIsOnAdminPage: boolean;
}

export const AttendanceFormButtons = ({
    newUserAttendance,
    handleAttendanceRegistration,
    event,
    formHasError,
    mutationIsLoading,
    loggedInUserIsOwnerOrCollaborator,
    loggedInUserIsOnAdminPage,
}: Props): JSX.Element => {
    const translations = useTranslation("translation", {
        keyPrefix: "attendance",
    }).t;

    const loggedInUser = useContext(LoggedInUserContext);

    const isAttending = newUserAttendance?.attendanceStatus === AttendanceStatus.Attending;
    const isOnWaitlist = newUserAttendance?.attendanceStatus === AttendanceStatus.Waiting;
    const isInvited = newUserAttendance?.attendanceStatus === AttendanceStatus.Invited;

    const getPrimaryButtonStatusAndText = (): {
        alternateAttendanceStatus: AttendanceStatus;
        buttonText: string;
    } => {
        if (!isAttending) {
            //TODO: add isFull property to EventDto (DN - 18/08/23)
            if (event.isFull || event.numberOfParticipants === event.maxAttendees) {
                if (isOnWaitlist) {
                    return {
                        alternateAttendanceStatus: AttendanceStatus.NotAttending,
                        buttonText: translations("removeFromWaitlist"),
                    };
                }

                return {
                    // Not attending and not on waitlist
                    alternateAttendanceStatus: AttendanceStatus.Waiting,
                    buttonText: translations("putOnWaitlist"),
                };
            }

            // event is not full and still not attending:
            return {
                alternateAttendanceStatus: AttendanceStatus.Attending,
                buttonText: translations("attending"),
            };
        }

        // Is attending
        return {
            alternateAttendanceStatus: AttendanceStatus.NotAttending,
            buttonText: translations("notattending"),
        };
    };

    const { alternateAttendanceStatus, buttonText } = getPrimaryButtonStatusAndText();
    const shareDietaryConsiderationsInNewStatus =
        alternateAttendanceStatus === AttendanceStatus.NotAttending ?
            false
        :   newUserAttendance?.shareDietaryConsiderations;

    const disableNotAttendingButton = () => {
        if (isFuture(event.registrationDeadline)) return false;

        if (isOnWaitlist) return false;

        if (
            (loggedInUser.isAdmin && loggedInUserIsOnAdminPage) ||
            loggedInUserIsOwnerOrCollaborator
        )
            return false;

        return true;
    };

    // https://github.com/Bouvet-deler/bouvet-events/issues/380
    return (
        <>
            <LoadingButton
                loading={mutationIsLoading}
                variant="contained"
                color={isAttending || isOnWaitlist ? "primary" : "secondary"}
                disabled={formHasError || mutationIsLoading || disableNotAttendingButton()}
                onClick={() =>
                    handleAttendanceRegistration({
                        ...newUserAttendance,
                        attendanceStatus: alternateAttendanceStatus,
                        shareDietaryConsiderations: shareDietaryConsiderationsInNewStatus,
                    } as AttendanceDto)
                }
            >
                {buttonText}
            </LoadingButton>
            {isInvited && !mutationIsLoading && (
                <LoadingButton
                    sx={{ ml: 2 }}
                    variant="contained"
                    color="primary"
                    disabled={formHasError}
                    loading={mutationIsLoading}
                    onClick={() =>
                        handleAttendanceRegistration({
                            ...newUserAttendance,
                            attendanceStatus: AttendanceStatus.NotAttending,
                            shareDietaryConsiderations: false,
                        } as AttendanceDto)
                    }
                >
                    {translations("notattending")}
                </LoadingButton>
            )}
            {(isAttending || isOnWaitlist) && !mutationIsLoading && (
                <LoadingButton
                    sx={{ ml: 2 }}
                    variant="contained"
                    color={"secondary"}
                    disabled={formHasError || mutationIsLoading}
                    onClick={() => handleAttendanceRegistration(newUserAttendance as AttendanceDto)}
                >
                    {translations("updateAttendance")}
                </LoadingButton>
            )}

            {event.isFull && isAttending && (
                <Typography>{translations("eventIsFullWarning")}</Typography>
            )}
        </>
    );
};
