export const getInitials = (name: string): string => {
    const nameArray: string[] = name.split(" ");
    const firstName = nameArray.shift();
    const lastName = nameArray.pop();
    let initials = "";

    if (firstName) {
        initials += firstName[0];
    }

    if (lastName) {
        initials += lastName[0];
    }

    return initials;
};
