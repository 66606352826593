import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Modal, Tooltip, Typography } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AttendanceDto, AttendanceStatus } from "../../api";
import { MutationSnackbar } from "../ui/MutationSnackbar";
import { UserAvatar } from "../ui/UserAvatar";
import { StyledModalBox, StyledResponseButton } from "../ui/styled-components";
import { AttendanceForm } from "./AttendanceForm";
import { useUserAttendanceMutation } from "./queries";
import { AttendanceModalEventInfo } from "./types";

interface Props {
    disableModal: boolean;
    event: AttendanceModalEventInfo;
    isEventOpen: boolean;
    attendance?: AttendanceDto;
    userName?: string;
    userImage?: string;
    isAdminOrOwnerEditing: boolean;
    waitListSpot?: number;
}

export const AttendanceModal = ({ disableModal, event, isEventOpen, attendance, userName, userImage, isAdminOrOwnerEditing, waitListSpot }: Props): JSX.Element => {
    const translations = useTranslation("translation", {
        keyPrefix: "attendance",
    }).t;

    const {
        isLoading,
        isSuccess,
        isError,
        isIdle,
        mutate: attendanceRegistrationMutate,
    } = useUserAttendanceMutation(attendance?.userId ?? "", attendance?.eventId ?? "");

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const attendanceStatus = attendance?.attendanceStatus;

    const getButtonText = () => {
        const buttonTextKey = (!isEventOpen && attendanceStatus === AttendanceStatus.Invited)
            ? "notOpen"
            : (!attendanceStatus || attendanceStatus === AttendanceStatus.Invited
                ? (!event.isFull ? "respond" : AttendanceStatus.Waiting.toLocaleLowerCase())
                : attendanceStatus.toLocaleLowerCase());

        if (attendance?.attendanceStatus === AttendanceStatus.Waiting) {
            if (!waitListSpot) return translations(buttonTextKey);

            return `${translations("waitListSpotPrefix")} ${waitListSpot} ${translations("waitListSpotSuffix")}`;
        }
        return translations(buttonTextKey);
    };

    useEffect(() => {
        if (isSuccess) handleClose();
    }, [isSuccess]);

    return (
        <>
            {disableModal ?
                <StyledResponseButton variant="contained" sx={{ width: "164px" }} disabled>
                    {getButtonText()}
                </StyledResponseButton>
            :   <Tooltip title={translations("editAttendance")}>
                    <StyledResponseButton
                        onClick={handleOpen}
                        variant="contained"
                        color={
                            (
                                attendanceStatus === AttendanceStatus.Invited &&
                                !isAdminOrOwnerEditing
                            ) ?
                                "secondary"
                            : (
                                attendanceStatus === AttendanceStatus.Attending &&
                                !isAdminOrOwnerEditing
                            ) ?
                                "attending"
                            : (
                                attendanceStatus === AttendanceStatus.Waiting &&
                                !isAdminOrOwnerEditing
                            ) ?
                                "waiting"
                            :   "primary"
                        }
                        sx={{ width: isAdminOrOwnerEditing ? "60px" : "164px" }}
                    >
                        {isAdminOrOwnerEditing ?
                            <>
                                <EditIcon />
                                <Typography sx={visuallyHidden}>
                                    {translations("editAttendance")}
                                </Typography>
                            </>
                        :   getButtonText()}
                    </StyledResponseButton>
                </Tooltip>
            }

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <StyledModalBox>
                    <Button onClick={handleClose} sx={{ float: "right" }} color="inherit">
                        <CloseIcon />
                    </Button>

                    <Box display="flex" alignItems={"center"} mb={1}>
                        <UserAvatar userName={userName} image={userImage} />
                        <Box ml={2}>
                            <Typography component="p" variant="subtitle1">
                                {userName}
                            </Typography>
                            <Typography component="p" variant="body2" sx={{ fontStyle: "italic" }}>
                                {translations(
                                    attendance?.attendanceStatus?.toLocaleLowerCase() ?? "invited"
                                )}
                            </Typography>
                        </Box>
                    </Box>
                    <AttendanceForm
                        userAttendance={attendance}
                        event={event}
                        handleAttendanceRegistration={attendanceRegistrationMutate}
                        mutationIsLoading={isLoading}
                    />
                </StyledModalBox>
            </Modal>
            <MutationSnackbar
                isLoading={!!isLoading}
                hasError={!!isError}
                isIdle={!!isIdle}
                successMessage={translations("saveRegistrationSuccess")}
                errorMessage={translations("saveRegistrationError")}
            />
        </>
    );
};
