import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import { Box, Button, Fab, Modal, Tooltip, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetExistingEventQuery } from "../Event/queries";
import { LoggedInUserContext } from "../LoggedInUser/provider";
import { MutationSnackbar } from "../ui/MutationSnackbar";
import { StyledModalBox } from "../ui/styled-components";
import { SendEmailForm } from "./SendEmailForm";
import { useSendEmailQuery } from "./queries";
import { EmailModalEventInfo } from "./types";

interface Props {
    event: EmailModalEventInfo;
}

export const EmailModal = ({ event }: Props): JSX.Element => {
    const translations = useTranslation("translation", {
        keyPrefix: "email",
    }).t;
    const loggedInUser = useContext(LoggedInUserContext);
    const { data: existingEvent } = useGetExistingEventQuery(event.eventId);
    const userIsOwner = !!(
        existingEvent?.owner?.id === loggedInUser?.id ||
        existingEvent?.collaborators?.some((c) => c.id === loggedInUser.id) ||
        loggedInUser.isAdmin
    );

    const sendEmailMutation = useSendEmailQuery();
    const { isLoading, isError, isIdle } = sendEmailMutation;

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            {userIsOwner ?
                <Tooltip title={translations("sendEmail")}>
                    <Fab color="secondary" aria-label="Copy" size="medium" onClick={handleOpen}>
                        <EmailIcon />
                    </Fab>
                </Tooltip>
            :   <></>}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <StyledModalBox>
                    <Button onClick={handleClose} sx={{ float: "right" }} color="inherit">
                        <CloseIcon />
                    </Button>
                    <Box display="flex" alignItems={"center"} mb={1}>
                        <Typography variant="h6" component="h2">
                            {translations("sendEmailToRecipients")}
                            {event?.name}
                        </Typography>
                    </Box>
                    <SendEmailForm
                        handleClose={handleClose}
                        event={event}
                        loggedInUser={loggedInUser}
                    />
                </StyledModalBox>
            </Modal>
            <MutationSnackbar
                isLoading={isLoading}
                hasError={isError}
                isIdle={isIdle}
                successMessage={translations("saveRegistrationSuccess")}
                errorMessage={translations("saveRegistrationError")}
            />
        </>
    );
};
