import { LinkRowData, Order } from "./types";

export function getComparator(order: Order, orderBy: string | undefined) {
    return (a: LinkRowData, b: LinkRowData) => {
        const aValue = getColumnValue(a, orderBy);
        const bValue = getColumnValue(b, orderBy);

        if (aValue === undefined && bValue === undefined) {
            return 0;
        } else if (aValue === undefined) {
            return order === "asc" ? 1 : -1;
        } else if (bValue === undefined) {
            return order === "asc" ? -1 : 1;
        }
        if (aValue < bValue) {
            return order === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
            return order === "asc" ? 1 : -1;
        }
        return 0;
    };
}

function getColumnValue(row: LinkRowData, columnName: string | undefined) {
    const column = row.columns.find((col) => col.key === columnName);
    if (column) {
        if (typeof column.content === "string" || typeof column.content === "number") {
            return column.content;
        } else if (column.stringContent) {
            return column.stringContent;
        }
    }
    return undefined;
}

export function stableSort(
    array: readonly LinkRowData[],
    comparator: (a: LinkRowData, b: LinkRowData) => number
) {
    const stabilizedThis = array.map((el, index) => ({ el, index }));
    stabilizedThis.sort((a, b) => {
        const order = comparator(a.el, b.el);
        if (order !== 0) {
            return order;
        }
        return a.index - b.index;
    });
    return stabilizedThis.map((el) => el.el);
}
