import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AttendanceDto, useApiClient } from "../../api";

export const useUserAttendanceMutation = (userId: string, eventId: string) => {
    const { attendanceClient } = useApiClient();
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ["attendance", userId, eventId],
        mutationFn: async (attendance: AttendanceDto) => {
            return await attendanceClient.updateUserAttendance(attendance);
        },
        onSuccess: () => {
            // Invalidate and refetch queries
            queryClient.invalidateQueries({ queryKey: ["futureEvents"] });
            queryClient.invalidateQueries({ queryKey: ["event", eventId] });
            queryClient.invalidateQueries({
                queryKey: ["eventAttendance", eventId],
            });
            queryClient.invalidateQueries({
                queryKey: ["userAttendance", eventId, userId],
            });
            queryClient.invalidateQueries({
                queryKey: ["eventAttendeesList", eventId],
            });
            queryClient.invalidateQueries({
                queryKey: ["dietaryConsiderationsAsStringListForEvent", eventId],
            });
            queryClient.invalidateQueries({
                queryKey: ["attendeeNamesAsString", eventId],
            });
        },
    });
};
