import { Paper } from "@mui/material";
import { isPast } from "date-fns";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { EventAttendanceTable } from "../../EventAttendanceTable";
import { EventDetailsActionButtons } from "../../EventDetailsActionButtons";
import { LoggedInUserContext } from "../../LoggedInUser/provider";
import { GenericError, NotFound } from "../../error";
import { EventInfo } from "../../ui/Event/EventInfo";
import { SpinnerLargeCenter } from "../../ui/Spinner";
import { useAdminGetEventQuery } from "../queries";

interface Props {
    eventId: string;
}

export const AdminDetailsRoute = () => {
    const translations = useTranslation("translation", {
        keyPrefix: "admin",
    }).t;

    document.title = "Admin - Bouvet Events";

    const { eventId } = useParams();
    const loggedInUser = useContext(LoggedInUserContext);

    if (!eventId) {
        return <NotFound />;
    }

    if (!loggedInUser?.isAdmin) {
        return <GenericError message={translations("noAccess")} />;
    }

    return <AdminDetails eventId={eventId!} />;
};

const AdminDetails = ({ eventId }: Props) => {
    const { data: event, isLoading, isError } = useAdminGetEventQuery(eventId);

    if (isLoading) return <SpinnerLargeCenter />;

    if (!event?.id || isError) return <NotFound />;

    const isArchivedEvent = event.endDateTime ? isPast(event.endDateTime) : true;

    return (
        <>
            <Paper sx={{ p: 4, position: "relative" }}>
                <EventInfo event={event} />
                <EventDetailsActionButtons
                    eventId={eventId}
                    isArchivedEvent={isArchivedEvent}
                    isAdmin
                />
            </Paper>
            <Paper
                sx={{
                    mt: "5px",
                    padding: "16px",
                    width: "auto",
                    overflowX: "auto",
                }}
            >
                <EventAttendanceTable eventId={event.id} userIsOwnerOrAdmin={true} />
            </Paper>
        </>
    );
};
