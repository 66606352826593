import { createContext, memo } from "react";
import { IUser } from "../../api";
import { NoLoggedInUserPage } from "../error";
import { useLoggedInUserQuery } from "./queries";

export const LoggedInUserContext = createContext<IUser>(undefined as never);

interface Props {
    children: React.ReactNode;
}

export const LoggedInUserProviderComponent = ({ children }: Props): JSX.Element => {
    const { data: loggedInUser, isLoading } = useLoggedInUserQuery();
    // const queryClient = useQueryClient();

    if (!loggedInUser && !isLoading) {
        // The redirect to login page happends in QueryProvider.tsx
        // queryClient.invalidateQueries({ queryKey: ["loggedInUser"] })
        return <NoLoggedInUserPage />;
    }

    return (
        <LoggedInUserContext.Provider
            value={{
                ...loggedInUser,
            }}
        >
            {children}
        </LoggedInUserContext.Provider>
    );
};

export const LoggedInUserProvider = memo(LoggedInUserProviderComponent);
