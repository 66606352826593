import { Routes as ReactRoutes, Route } from "react-router-dom";
import { AdminDetailsRoute } from "./features/Admin/Details";
import { AdminOverview } from "./features/Admin/Overview";
import { CopyEvent } from "./features/Event/CopyEvent";
import { CreateEvent } from "./features/Event/CreateEvent";
import { EditEvent } from "./features/Event/EditEvent";
import { EventDetailsRoute } from "./features/Event/EventDetails";
import { EventsOverview } from "./features/EventsOverview";
import { Preferences } from "./features/LoggedInUser/Preferences";
import { GenericError, NotFound } from "./features/error/";
import { PageLayout } from "./features/ui/PageLayout";

export const Routes = () => {
    return (
        <ReactRoutes>
            <Route path="/" element={<PageLayout />}>
                <Route index element={<EventsOverview />} />
                <Route path="/upcomingEvents" element={<EventsOverview currentTabValue={0} />} />
                <Route path="/participations" element={<EventsOverview currentTabValue={1} />} />
                <Route
                    path="/yourUpcomingEvents"
                    element={<EventsOverview currentTabValue={3} />}
                />
                <Route path="/previousEvents" element={<EventsOverview currentTabValue={2} />} />
                <Route
                    path="/yourPreviousEvents"
                    element={<EventsOverview currentTabValue={4} />}
                />

                <Route path="/event/:eventId/edit" element={<EditEvent />} />
                <Route path="/event/:eventId/copy" element={<CopyEvent />} />
                <Route path="/event/:eventId" element={<EventDetailsRoute />} />
                <Route path="/event/create" element={<CreateEvent />} />
                <Route path="/error" element={<GenericError />} />
                <Route path="/settings" element={<Preferences />} />

                <Route path="/admin" element={<AdminOverview />} />
                <Route
                    path="/admin/upcomingEvents"
                    element={<AdminOverview currentTabValue={0} />}
                />
                <Route
                    path="/admin/previousEvents"
                    element={<AdminOverview currentTabValue={1} />}
                />
                <Route path="/admin/event/:eventId" element={<AdminDetailsRoute />} />

                <Route path="/not-found" element={<NotFound />} />
                <Route path="*" element={<NotFound />} />
            </Route>
        </ReactRoutes>
    );
};
