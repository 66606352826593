import { Grid, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledTabs, TabType } from "../../ui/Tabs";
import { AdminEventTable } from "./AdminEventTable";

const tabKeys = ["upcomingEvents", "previousEvents"];

interface Props {
    currentTabValue?: number;
}

export const AdminOverview = ({ currentTabValue = 0 }: Props) => {
    const translations = useTranslation("translation", {
        keyPrefix: "admin",
    }).t;

    const tabs: TabType[] = tabKeys.map((key, index) => {
        return {
            id: index,
            name: key,
            displayText: translations(key),
            url: `/admin/${key}`,
        };
    });

    const isFutureEvent = tabs[currentTabValue].name == "upcomingEvents";

    return (
        <>
            <Grid container>
                <Grid item lg={2} xs={12}>
                    <StyledTabs tabValue={currentTabValue} tabs={tabs} />
                </Grid>
                <Grid item lg={10} xs={12}>
                    <Paper
                        sx={{
                            padding: "16px",
                            width: "auto",
                            overflowX: "auto",
                        }}
                    >
                        <AdminEventTable isFutureEvent={isFutureEvent} />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};
