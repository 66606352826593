import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { EventDto, useApiClient } from "../../api";

export const useGetCreateEventTemplateQuery = () => {
    const { eventClient } = useApiClient();

    return useQuery(["createEvent"], async () => {
        return await eventClient.getCreateEventDto();
    });
};


export const useGetCopyEventTemplateQuery = (eventId: string) => {
    const { eventClient } = useApiClient();

    return useQuery(["copyEvent", eventId], async () => {
        return await eventClient.getEventCopyDto(eventId);
    });
};


export const useGetEditEventTemplateQuery = (eventId: string) => {
    const { eventClient } = useApiClient();

    return useQuery(["editEvent", eventId], async () => {
        return await eventClient.getEditEventDto(eventId);
    });
};

export const useGetExistingEventQuery = (eventId: string) => {
    const { eventClient } = useApiClient();

    return useQuery(["event", eventId], async () => {
        return await eventClient.event(eventId);
    });
};

export const useCreateEventQuery = (handleEventCreated: (eventId: string) => void) => {
    const { eventClient } = useApiClient();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (event: EventDto) => {
            return await eventClient.create(event);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["futureEvents", true] });
            queryClient.invalidateQueries({
                queryKey: ["futureEvents", false],
            });
            queryClient.invalidateQueries({ queryKey: ["adminFutureEvents"] });

            handleEventCreated(data);
        },
    });
};


export const useUpdateEventQuery = (eventId: string, handleEventEdited: (eventId: string) => void) => {
    const { eventClient } = useApiClient();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (event: EventDto) => {
            return await eventClient.update(event);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["event", eventId] });
            queryClient.invalidateQueries({ queryKey: ["editEvent", eventId] });
            queryClient.invalidateQueries({
                queryKey: ["eventAttendeesList", eventId],
            }); // To make the list of attendees be correct after edit
            queryClient.invalidateQueries({ queryKey: ["futureEvents"] });

            handleEventEdited(data);
        },
    });
};

export const useDeleteEventQuery = (eventId: string) => {
    const { eventClient } = useApiClient();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async () => {
            return await eventClient.delete(eventId);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["event", eventId] });
            queryClient.invalidateQueries({
                queryKey: ["editEvent", eventId],
                refetchType: "none",
            });
            queryClient.invalidateQueries({ queryKey: ["futureEvents"] });
        },
    });
};
