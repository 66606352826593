import { Theme, useTheme } from "@mui/material";
import { TFunction } from "i18next";
import { createContext, memo } from "react";
import { useTranslation } from "react-i18next";

export interface EventContextProps {
    theme: Theme;
    translations: TFunction<"translation", "event">;
}

export const EventContext = createContext<EventContextProps>(undefined as never);

interface Props {
    children: React.ReactNode;
}

export const EventProviderComponent = ({ children }: Props): JSX.Element => {
    const translations = useTranslation("translation", {
        keyPrefix: "event",
    }).t;
    const theme = useTheme();

    return (
        <EventContext.Provider
            value={{
                theme,
                translations,
            }}
        >
            {children}
        </EventContext.Provider>
    );
};

export const EventProvider = memo(EventProviderComponent);
