import { $generateNodesFromDOM } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot, $insertNodes } from "lexical";
import { useEffect } from "react";

interface Props {
    value: string;
}

export const DeserializeHtmlPlugin = ({ value }: Props) => {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        if (!value) {
            return;
        }

        editor.update(() => {
            $getRoot().clear();
            // In the browser you can use the native DOMParser API to parse the HTML string.
            const parser = new DOMParser();
            const dom = parser.parseFromString(value, "text/html");

            // Once you have the DOM instance it's easy to generate LexicalNodes.
            const nodes = $generateNodesFromDOM(editor, dom);
            // Select the root
            $getRoot().select();

            // Insert them at a selection.
            $insertNodes(nodes);
        });
    }, []);

    return null;
};
