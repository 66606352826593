import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AttendanceDto, UserPreferences, useApiClient } from "../../api";

export const useLoggedInUserQuery = () => {
    const { userClient } = useApiClient();

    return useQuery(
        ["loggedInUser"],
        async () => {
            return await userClient.getUserWithPreferences();
        },
        {
            retry: false, // Disable automatic retries for this query
        }
    );
};

export const useGetLoggedInUserEventAttendanceQuery = (eventId: string, enableQuery = true) => {
    const { attendanceClient } = useApiClient();

    return useQuery(
        ["eventAttendance", eventId],
        async () => {
            return await attendanceClient.getLoggedInUserAttendanceForEvent(eventId);
        },
        {
            enabled: enableQuery,
        }
    );
};

export const useLoggedInUserAttendanceMutation = (userId: string, eventId: string) => {
    const { attendanceClient } = useApiClient();
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ["attendance", userId, eventId],
        mutationFn: async (attendance: AttendanceDto) => {
            return await attendanceClient.updateUserAttendance(attendance);
        },
        onSuccess: () => {
            // Invalidate and refetch frontpage query
            queryClient.invalidateQueries({ queryKey: ["futureEvents"] });
            queryClient.invalidateQueries({
                queryKey: ["eventAttendance", eventId],
            });
        },
    });
};

export const useUserPreferenceMutation = () => {
    const { userClient } = useApiClient();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (preferences: UserPreferences) => {
            return await userClient.updateUserPreferences(preferences);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["loggedInUser"] });
        },
    });
};
