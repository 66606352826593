import { CodeNode } from "@lexical/code";
import { $generateHtmlFromNodes } from "@lexical/html";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode } from "@lexical/rich-text";
import { useTheme } from "@mui/material";
import { EditorState, LexicalEditor } from "lexical";
import { Placeholder } from "./Placeholder";
import {
    AutoLinkPlugin,
    DeserializeHtmlPlugin,
    ListMaxIndentLevelPlugin,
    ToolbarPlugin,
} from "./plugins";
import "./styles.css";
import Theme from "./theme/Theme";

// I do not completely understand this component, but made some fixes to it to handle onChange. Feel free to rewrite (MG, 29.09)

const editorConfig = {
    theme: Theme,
    // Handling of errors during update
    onError(error: unknown) {
        throw error;
    },
    // Any custom nodes go here
    nodes: [HeadingNode, ListNode, ListItemNode, CodeNode, AutoLinkNode, LinkNode],
    namespace: "richTextEditor",
    ContentEditable: false,
};

interface RichTextEditorProps {
    onChange: (value: string) => void;
    value: string;
    placeholderText: string;
    height?: string | number;
}

export const RichTextEditor = ({ onChange, value, placeholderText, height }: RichTextEditorProps) => {
    const theme = useTheme();

    const customOnChange = (_: EditorState, editor: LexicalEditor) => {
        editor.update(() => onChange($generateHtmlFromNodes(editor, null)));
    };

    return (
        <LexicalComposer initialConfig={editorConfig}>
            <div className="editor-container">
                <ToolbarPlugin />
                <div style={{ overflow: "auto", height: "100%" }}>

                    <RichTextPlugin
                        contentEditable={
                            <ContentEditable className="editor-input" style={{ color: theme.palette.text.secondary, height: height }} />
                        }
                        placeholder={<Placeholder placeholderText={placeholderText} />}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                </div>
                <OnChangePlugin onChange={customOnChange} />
                <DeserializeHtmlPlugin value={value} />
                <ListPlugin />
                <LinkPlugin />
                <AutoLinkPlugin />
                <ListMaxIndentLevelPlugin maxDepth={7} />
            </div>
        </LexicalComposer>
    );
};
