import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Checkbox, IconButton, Typography } from "@mui/material";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Department } from "../../../api";
import { NoneIcon } from "./NoneIcon";

interface Props {
    rootDepartment: Department;
    selectedDeptartmentIds: string[];
    setSelectedDeptartmentIds: (ids: string[]) => void;
}
export const OrganizationalStructureSelect = ({
    rootDepartment,
    selectedDeptartmentIds,
    setSelectedDeptartmentIds,
}: Props) => {
    const [selectedTree, setSelectedTree] = useState<string[]>([]);
    const [expandedIds, setExpandedIds] = useState<string[]>([]);

    rootDepartment.id = "123";

    const getAllChildIdsRecursive = useCallback(
        (department: Department, ids: string[] = [], stopword = "") => {
            if (stopword && department.name?.includes(stopword)) {
                return ids;
            }

            ids.push(department.id!);
            if (department.subDepartments?.length) {
                department.subDepartments.forEach((child) =>
                    getAllChildIdsRecursive(child, ids, stopword)
                );
            }
            return ids;
        },
        []
    );

    useEffect(() => {
        // get a list of selected Ids?
        const ids = getAllChildIdsRecursive(rootDepartment, [], "Region");
        setExpandedIds(ids);
    }, [rootDepartment, getAllChildIdsRecursive]);

    const getAllChildIdsThatShouldBeAddedOrRemovedFromList = (
        department: Department,
        shouldBeAdded: boolean,
        listToCompare: string[]
    ) => {
        const ids = getAllChildIdsRecursive(department);
        let updatedIds = listToCompare;
        ids.forEach((id) => {
            if (shouldBeAdded && !listToCompare.includes(id)) {
                updatedIds.push(id); // add the id
            }
            if (!shouldBeAdded && listToCompare.includes(id)) {
                updatedIds = updatedIds.filter((s) => s !== id); // remove the id
            }
        });
        return updatedIds;
    };

    const handleSelect = (department: Department, markSelected: boolean): void => {
        const updatedIds = getAllChildIdsThatShouldBeAddedOrRemovedFromList(
            department,
            markSelected,
            selectedDeptartmentIds
        );
        setSelectedDeptartmentIds(updatedIds);
    };

    const handleExpansion = (department: Department, shouldExpand: boolean) => {
        const id = department.id!;
        if (shouldExpand) {
            setExpandedIds(expandedIds.concat(id));
        } else {
            setExpandedIds(expandedIds.filter((x) => x !== id));
        }
    };

    const handleRowClick = (
        e: React.SyntheticEvent,
        department: Department,
        isSelected: boolean
    ) => {
        e.stopPropagation();
        // A little "hack" to not select/deselect departments when the expansion button is pressed. Feel tree to rewrite if you find a better solution. #makeItWork #makeItBetter (MG, 28/7/23)
        const targetText: string = e.target?.toString() ?? "";
        const isExpandButton =
            targetText.includes("SVG") || targetText.includes("HTMLButtonElement");
        if (isExpandButton) {
            return;
        } // End of little hack

        handleSelect(department, !isSelected);
    };

    const renderStructure = (department: Department): JSX.Element | null => {
        if (!department.id) {
            department.id = department.name;
        }

        const id = department.id!;
        const isSelected = selectedDeptartmentIds.includes(id);
        const isExpanded = expandedIds.includes(id);
        return (
            <TreeItem
                key={id}
                itemId={id}
                color="secondary"
                sx={{
                    width: "100%",
                    ".MuiTreeItem-iconContainer": { display: "none" },
                }}
                onClick={(e: SyntheticEvent<Element, Event>) =>
                    handleRowClick(e, department, isSelected)
                }
                slots={{ expandIcon: NoneIcon, collapseIcon: NoneIcon }}
                label={
                    <>
                        <Checkbox
                            checked={isSelected}
                            tabIndex={-1}
                            color="secondary"
                            disableRipple
                            onClick={() => handleSelect(department, !isSelected)}
                        />
                        <Typography component="span" variant="body2">
                            {department.name}
                        </Typography>
                        {department.subDepartments?.length ?
                            <IconButton
                                sx={{ float: "right" }}
                                onClick={() => handleExpansion(department, !isExpanded)}
                            >
                                {isExpanded ?
                                    <ExpandMoreIcon />
                                :   <ChevronRightIcon />}
                            </IconButton>
                        :   null}
                    </>
                }
            >
                {department.subDepartments?.length ?
                    department.subDepartments.map((d) => renderStructure(d))
                :   null}
            </TreeItem>
        );
    };

    return (
        <SimpleTreeView
            multiSelect
            selectedItems={selectedTree}
            expandedItems={expandedIds}
            // onNodeToggle={handleToggle} // Maybe check out if this gives us nice stuff --- I checked, this is triggered when the expand button is pressed. (Steinar)
            onSelectedItemsChange={(_: React.SyntheticEvent, nodeIds: string[]) =>
                setSelectedTree(nodeIds)
            }
        >
            {renderStructure(rootDepartment)}
        </SimpleTreeView>
    );
};
