/* eslint-disable @typescript-eslint/no-explicit-any */
export type FormFieldValue = string | number | boolean | Date | null | undefined;

export interface ValidationType {
    isValid: any;
    errorMsgKeys: string[];
}

export type ValidationResult = {
    [fieldId: string]: string | null;
};

export interface ValidationRules {
    [fieldId: string]: any[];
}

export const validationTypes = {
    required: {
        isValid: (value: string | number | Date): boolean => !!value,
        errorMsgKeys: ["event.form.validation.required"],
    },
    ifFieldHasValue: (otherFieldId: string, rules: ValidationType[] = []): object => ({
        isValid: (value: string, formData: any): boolean =>
            formData[otherFieldId] ?
                rules.map((r) => r.isValid(value)).every((isValid) => isValid)
            :   true,
        errorMsgKeys: ["event.form.validation.required"],
    }),
    numberLargerThanZero: {
        isValid: (value: number): boolean => value > 0,
        errorMsgKeys: ["event.form.validation.valueLargerThanZero"],
    },
    numberLargerThan: (comp: number): object => ({
        isValid: (value: number): boolean => value > comp,
        errorMsgKeys: ["event.form.validation.valueLargerThan", comp.toString()],
    }),
    numberLargerThanOtherField: (otherFieldId: string): object => ({
        isValid: (value: number, formData: any): boolean =>
            !formData[otherFieldId] || value >= formData[otherFieldId],
        errorMsgKeys: ["event.form.validation.valueLargerThan", `event.form.${otherFieldId}`],
    }),
    numberSmallerThanOtherField: (otherFieldId: string): object => ({
        isValid: (value: number, formData: any): boolean =>
            !formData[otherFieldId] || value <= formData[otherFieldId],
        errorMsgKeys: ["event.form.validation.valueSmallerThan", `event.form.${otherFieldId}`],
    }),
    isAfterDate: (otherFieldId: string, errorMsgKeys: string[]): object => ({
        isValid: (value: Date, formData: any): boolean => value >= (formData[otherFieldId] as Date),
        errorMsgKeys: errorMsgKeys,
    }),
    isBeforeDate: (otherFieldId: string, errorMsgKeys: string[]): object => ({
        isValid: (value: Date, formData: any): boolean => value <= (formData[otherFieldId] as Date),
        errorMsgKeys: errorMsgKeys,
    }),
};
