import { InfoOutlined } from "@mui/icons-material";
import {
    Box,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { isFuture, isPast } from "date-fns";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AttendanceDto, IAttendanceDto } from "../../api";
import { useGetExistingEventQuery } from "../Event/queries";
import { LoggedInUserContext } from "../LoggedInUser/provider";
import { AttendanceFormButtons } from "./AttendanceFormButtons";
import { AttendanceModalEventInfo } from "./types";

interface Props {
    userAttendance?: AttendanceDto;
    event: AttendanceModalEventInfo;
    handleAttendanceRegistration: (userAttendance: AttendanceDto) => void;
    mutationIsLoading?: boolean;
}

export const AttendanceForm = ({
    userAttendance,
    handleAttendanceRegistration,
    event,
    mutationIsLoading,
}: Props): JSX.Element => {
    const translations = useTranslation("translation", {
        keyPrefix: "attendance",
    }).t;

    const { data: eventQuery } = useGetExistingEventQuery(event.eventId);

    const loggedInUser = useContext(LoggedInUserContext);

    const [newUserAttendance, setNewAttendanceDto] = useState<IAttendanceDto | undefined>(
        userAttendance
    );
    const [formErrors, setFormErrors] = useState({
        comment: false,
        guests: false,
    });

    const loggedInUserIsOwnerOrCollaborator = !!(
        eventQuery?.owner?.id === loggedInUser?.id ||
        eventQuery?.collaborators?.some((c) => c.id === loggedInUser.id)
    );

    const loggedInUserIsOnAdminPage = location.pathname.indexOf("admin") >= 0;

    const disableGuestCountFieldInAttendanceModal = () => {
        if (isFuture(event.registrationDeadline)) return false;

        if (
            isPast(event.registrationDeadline) &&
            ((loggedInUser.isAdmin && loggedInUserIsOnAdminPage) ||
                loggedInUserIsOwnerOrCollaborator)
        )
            return false;

        return true;
    };

    const commentMaxLength = 250;

    const onCommentChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const comment = e.target?.value ?? "";

        if (comment.length > commentMaxLength) {
            setFormErrors({ ...formErrors, comment: true });
            return;
        }

        setFormErrors({ ...formErrors, comment: false });
        setNewAttendanceDto({ ...newUserAttendance, comment });
    };

    const onGuestChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        maxNum: number
    ): void => {
        let number = newUserAttendance?.guestCount;
        try {
            number = parseInt(e.target.value);
        } catch {
            setFormErrors({ ...formErrors, guests: true });
            return;
        }

        if (number < 0 || number > maxNum) {
            setFormErrors({ ...formErrors, guests: true });
            return;
        }

        setFormErrors({ ...formErrors, guests: false });
        setNewAttendanceDto({ ...newUserAttendance, guestCount: number });
    };

    useEffect(() => {
        setNewAttendanceDto(userAttendance);
    }, [userAttendance]);

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    cursor: "pointer",
                }}
            >
                <TextField
                    variant="standard"
                    label={translations("comment")}
                    maxRows={20}
                    placeholder={translations("commentPlaceholder")}
                    color="secondary"
                    sx={{ width: "90%", mt: 2 }}
                    onChange={onCommentChange}
                    error={formErrors.comment}
                    InputLabelProps={{ shrink: true }}
                    multiline
                    value={newUserAttendance?.comment ?? ""}
                    type="text"
                />

                <Tooltip
                    title={translations("commentTooltip")}
                    placement="bottom-end"
                    enterDelay={250}
                >
                    <InfoOutlined sx={{ mt: 5, ml: 2 }} />
                </Tooltip>
            </div>
            <FormHelperText>
                {(newUserAttendance?.comment?.length ?? 0) + "/" + commentMaxLength}
            </FormHelperText>

            {/* Only let the logged in user share the preferences */}
            {userAttendance?.userId === loggedInUser.id ?
                <>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                    >
                        <FormControlLabel
                            sx={{ mt: 0 }}
                            control={
                                <Checkbox
                                    disabled={loggedInUser.id !== newUserAttendance?.userId}
                                    color="secondary"
                                    checked={newUserAttendance?.shareDietaryConsiderations}
                                    onChange={(): void =>
                                        setNewAttendanceDto({
                                            ...newUserAttendance,
                                            shareDietaryConsiderations:
                                                !newUserAttendance?.shareDietaryConsiderations,
                                        })
                                    }
                                    value="shareDietaryConsiderations"
                                />
                            }
                            label={translations("shareDietaryConsiderations")}
                        />
                        <Tooltip
                            title={translations("dietaryConsiderationsToolTip")}
                            placement="right-start"
                            enterDelay={250}
                        >
                            <InfoOutlined />
                        </Tooltip>
                    </div>
                    {newUserAttendance?.shareDietaryConsiderations ?
                        <Typography variant="body2">
                            {loggedInUser.preferences?.dietaryConsiderations ?
                                translations("form.dietaryConsiderationsThatWillBeShared") +
                                loggedInUser.preferences?.dietaryConsiderations
                            :   translations("form.noDietaryConsiderations")}
                        </Typography>
                    :   null}
                </>
            :   null}

            {event?.maxGuestsPerAttendee && event?.maxGuestsPerAttendee > 0 ?
                <>
                    <TextField
                        label={translations("numberOfGuests")}
                        variant="standard"
                        color="secondary"
                        onChange={(e) => onGuestChange(e, event.maxGuestsPerAttendee!)}
                        fullWidth={false}
                        disabled={disableGuestCountFieldInAttendanceModal()}
                        error={!!formErrors.guests}
                        type="number"
                        value={newUserAttendance?.guestCount ?? 0}
                        sx={{ width: "100%", mt: 1 }}
                    />
                    <FormHelperText disabled={disableGuestCountFieldInAttendanceModal()}>
                        {translations("form.guestsMaxIs") + event.maxGuestsPerAttendee}
                    </FormHelperText>
                </>
            :   null}

            <Box sx={{ mt: 3 }}>
                <AttendanceFormButtons
                    newUserAttendance={newUserAttendance}
                    event={event}
                    handleAttendanceRegistration={handleAttendanceRegistration}
                    formHasError={formErrors.comment || formErrors.guests}
                    mutationIsLoading={mutationIsLoading}
                    loggedInUserIsOwnerOrCollaborator={loggedInUserIsOwnerOrCollaborator}
                    loggedInUserIsOnAdminPage={loggedInUserIsOnAdminPage}
                />
            </Box>
        </>
    );
};
