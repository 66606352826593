import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import NoMealsIcon from "@mui/icons-material/NoMeals";
import { Button, Modal, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledModalInfoBox } from "../ui/styled-components";
import { useGetDietaryConsiderationsAsStringListForEventQuery } from "./queries";

interface AttendanceAllergyModalProps {
    eventId: string;
}

export const AttendanceAllergyModal = ({ eventId }: AttendanceAllergyModalProps) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const translations = useTranslation("translation", {
        keyPrefix: "event",
    }).t;

    const { data: dietaryConsiderationList } =
        useGetDietaryConsiderationsAsStringListForEventQuery(eventId);

    return (
        <>
            <Box
                display="flex"
                alignItems={"center"}
                mt={0}
                flexDirection={"row"}
                justifyContent={"center"}
            >
                <Button
                    sx={{ mr: 1, mb: 1, width: "170px" }}
                    color="secondary"
                    variant="contained"
                    onClick={handleOpen}
                >
                    <NoMealsIcon />
                    {translations("details.download.showAllergyModal")}
                </Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <StyledModalInfoBox sx={{ p: 2, px: 4 }}>
                        <Box alignItems={"center"} display="flex" mt={2}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {translations("details.download.allergies")}
                            </Typography>
                            <Tooltip title={translations("details.download.copyAllergies")}>
                                <Button
                                    sx={{ ml: 28 }}
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            dietaryConsiderationList?.join(" ,") ?? ""
                                        );
                                    }}
                                    color="secondary"
                                    variant="contained"
                                >
                                    <ContentCopyIcon />
                                </Button>
                            </Tooltip>
                        </Box>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} component="div">
                            <Box component="div" sx={{ overflowY: "auto", maxHeight: "500px" }}>
                                {dietaryConsiderationList?.length === 0 ?
                                    "Ingen"
                                :   dietaryConsiderationList?.map((item, index) => (
                                        <div key={index}>{item}</div>
                                    ))
                                }
                            </Box>
                        </Typography>
                    </StyledModalInfoBox>
                </Modal>
            </Box>
        </>
    );
};
