import { ValidationRules, validationTypes } from "./types";

export const eventValidationRules: ValidationRules = {
    name: [validationTypes.required],
    owner: [validationTypes.required],
    maxAttendees: [
        validationTypes.numberLargerThan(-1),
        validationTypes.numberLargerThanOtherField("minAttendees"),
    ],
    minAttendees: [
        validationTypes.numberLargerThan(-1),
        validationTypes.numberSmallerThanOtherField("maxAttendees"),
    ],
    maxGuestsPerAttendee: [
        validationTypes.ifFieldHasValue("allowGuests", [
            validationTypes.required,
            validationTypes.numberLargerThanZero,
        ]),
    ],
    registrationDeadline: [
        validationTypes.isAfterDate("openingTimeForRegistration", [
            "event.form.registrationDeadline",
            "event.form.validation.mustBeAfter",
            "event.form.openingTimeForRegistration",
        ]),
        validationTypes.required,
    ],
    startDateTime: [
        validationTypes.isAfterDate("registrationDeadline", [
            "event.form.startDateTime",
            "event.form.validation.mustBeAfter",
            "event.form.registrationDeadline",
        ]),
        validationTypes.required,
    ],
    endDateTime: [
        validationTypes.isAfterDate("startDateTime", [
            "event.form.endDateTime",
            "event.form.validation.mustBeAfter",
            "event.form.startDateTime",
        ]),
        validationTypes.required,
    ],
    openingTimeForRegistration: [
        validationTypes.isBeforeDate("registrationDeadline", [
            "event.form.openingTimeForRegistration",
            "event.form.validation.mustBeBefore",
            "event.form.registrationDeadline",
        ]),
        validationTypes.required,
    ],
};
