import { Components, PaletteMode, createTheme } from "@mui/material";
import { useMemo } from "react";

// Create TypeScript Definitions .
declare module "@mui/material/styles" {
    interface Palette {
        attending: string;
        waiting: string;
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        attending: true;
        waiting: true;
    }
}

const fontFamily = ["Source Sans Pro", "'Helvetica Neue'", "Helvetica", "sans-serif"].join(",");

const lightPalette = {
    // palette values for light mode
    primary: {
        main: "#ECECEC", // Grey base color
        contrastText: "#4A4A4A", // Darker grey
    },
    secondary: {
        main: "#11133C", // Henriette Blue // Cloak blue #0617BF
        contrastText: "#FFFFFF", // White
    },
    attending: createTheme().palette.augmentColor({
        color: {
            main: "#79FE9C", // Similar to Network Green #97FFB3
            contrastText: "#000000", // Black
        },
    }),
    waiting: createTheme().palette.augmentColor({
        color: {
            main: "#C3D5E1", // Worp Blue
            contrastText: "#000000", // Black
        },
    }),
    text: {
        primary: "#222222", // Black
        secondary: "#222222", // "#7A7A7A" // Lighter black
    },
    info: {
        main: "rgba(17, 19, 60, 0.1)", // Used for the EventTableRow
        light: "rgba(249,168,111, 0.1)", // Used for the EventTableRow
    },
    background: {
        default: "#FCF8F3",
    },
};

const darkPalette = {
    // palette values for dark mode
    primary: {
        main: "#4A4A4A", // Grey base color
        contrastText: "#ECECEC", // Lighter grey
    },
    secondary: {
        main: "#FCF8F3", // Background color from lightPalette // Orange #F9A86F // Network Green #97FFB3
        contrastText: "#000000", // Black
    },
    attending: createTheme().palette.augmentColor({
        color: {
            main: "#79FE9C", // Similar to Network Green #97FFB3
            contrastText: "#000000", // Black
        },
    }),
    waiting: createTheme().palette.augmentColor({
        color: {
            main: "#C3D5E1", // Worp Blue
            contrastText: "#000000", // Black
        },
    }),
    text: {
        primary: "#DDDDDD", // White
        secondary: "#DDDDDD", // "#858585" // Darker white
    },
    info: {
        main: "rgba(66, 66, 66, 0.1)", // Used for the EventTableRow
        light: "rgba(66,66,66, 0.1)", // Used for the EventTableRow
    },
    background: {
        default: "#303030",
        paper: "#383838",
    },
};

const getTheme = (mode: PaletteMode) => ({
    palette: {
        mode,
        ...(mode === "light" ? lightPalette : darkPalette),
    },
});

const getComponentTheme = (mode: PaletteMode): Components<unknown> => ({
    MuiButton: {
        styleOverrides: {
            textPrimary: {
                color: mode === "light" ? lightPalette.secondary.main : darkPalette.secondary.main,
            },
        },
    },
    MuiInputLabel: {
        styleOverrides: {
            root: {
                "&$focused": {
                    color:
                        mode === "light" ? lightPalette.secondary.main : darkPalette.secondary.main,
                },
            },
        },
    },
    MuiTab: {
        styleOverrides: {
            root: {
                textTransform: "none",
                fontSize: "1rem",
                "@media (min-width: 600px)": {
                    fontSize: "1rem",
                },
            },
            wrapped: {
                alignItems: "start",
            },
        },
    },
});

export const Theme = (mode: PaletteMode) =>
    useMemo(
        () =>
            createTheme({
                palette: getTheme(mode).palette,
                components: getComponentTheme(mode),
                typography: {
                    h1: { fontFamily },
                    h2: { fontFamily },
                    h3: { fontFamily },
                    h4: { fontFamily },
                    h5: { fontFamily },
                    h6: { fontFamily },
                    // All other fonts
                    fontFamily: [
                        "Source Sans Pro",
                        "'Helvetica Neue'",
                        "Helvetica",
                        "sans-serif",
                    ].join(","),
                },
                breakpoints: {
                    values: {
                        xs: 0,
                        sm: 600,
                        md: 900,
                        lg: 1280, // With from the original event page (to keep the exact same layout)
                        xl: 1536,
                    },
                },
            }),
        [mode]
    );
